import {React, styled} from 'common'
import Colors from 'appearance/colors'
import {HomeFilled} from '@ant-design/icons'

const HomeIcon = () => {
    return (
        <Home>
            <HomeFilled onClick={() => window.location.replace('/')} />
        </Home>
    )
}

export default HomeIcon

const Home = styled.button`
    background-color: transparent;
    color: transparent;
    border-color: transparent;
    &:hover {
        cursor: pointer;
    }
    .anticon-home {
        font-size: 30px;
        position: relative;
        color: ${Colors.PRIMARY};
        padding-right: 35px;
        @media (max-width: 1200px) {
            padding-right: 39px;
            margin-left: 16px;
        }
    }
`
