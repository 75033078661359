import {
    addTrack,
    mapOptions,
    getMainTrackCoordinates,
    setCursorHoverMarkerOnMap,
    setMapBoundsFromSingleTrack,
    setCursorHoverOnTrackListener,
} from 'services/utils/maps'
import {Images} from 'appearance'
import {React, styled} from 'common'
import GoogleMapReact from 'google-map-react'
import {useState, useEffect, useRef} from 'services/hooks'
import Controlls from 'components/Control/Display/Map/Controlls'
import FullscreenButton from 'components/Control/Display/Map/FullscreenButton'
import SingleMapWidget from 'components/Control/Display/Map/Single/SingleMapWidget'

const SingleMap = ({coords, waypoints, cursorPosition, setCursorPosition}) => {
    const [mapObject, setMapObject] = useState(null)
    const [singleTrack, setSingleTrack] = useState(null)

    const cursorHoverMarker = useRef(null)

    useEffect(() => {
        setCursorHoverMarkerOnMap(
            cursorPosition,
            Images.map.Route,
            cursorHoverMarker,
            singleTrack,
            mapObject
        )
    }, [cursorPosition, mapObject, singleTrack, cursorHoverMarker])

    useEffect(() => {
        let cursorHoverOnMapListener
        if (mapObject && singleTrack) {
            const {maps} = mapObject
            cursorHoverOnMapListener = setCursorHoverOnTrackListener(
                maps,
                singleTrack,
                setCursorPosition
            )
        }
        return () => cursorHoverOnMapListener?.remove()
    }, [singleTrack, mapObject, setCursorPosition])

    const mapRef = useRef()

    if (!coords) {
        return null
    }

    const {trackCenter, trackStart, trackEnd, isRoundTrack} =
        getMainTrackCoordinates(coords)

    const handleApiLoaded = (map, maps) => {
        setMapObject({map, maps})
        const track = addTrack(coords, map, maps)
        setSingleTrack(track)
        setMapBoundsFromSingleTrack(track, map, maps)
    }

    return (
        <Container ref={mapRef}>
            <FullscreenButton mapRef={mapRef} />
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: window.GOOGLE_MAPS_KEY,
                }}
                defaultCenter={trackCenter}
                center={[trackCenter['lat'], trackCenter['lng']]}
                defaultZoom={14}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps)}
                options={mapOptions}>
                <SingleMapWidget
                    lat={trackStart.lat}
                    lng={trackStart.lng}
                    text={'start point'}
                    image={Images.map.Start}
                />
                {!isRoundTrack && (
                    <SingleMapWidget
                        lat={trackEnd.lat}
                        lng={trackEnd.lng}
                        text={'end point'}
                        image={Images.map.End}
                    />
                )}
                {waypoints.map((waypoint) => (
                    <SingleMapWidget
                        lat={waypoint.coordinates.lat}
                        lng={waypoint.coordinates.lng}
                        text={waypoint.description}
                        image={Images.thumbtack}
                    />
                ))}
            </GoogleMapReact>
            <Controlls mapObject={mapObject} mapCenter={trackCenter} />
        </Container>
    )
}

export default SingleMap

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 500px;
`
