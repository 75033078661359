import {styled} from 'common'
import 'assets/css/fonts.css'
import Colors from 'appearance/colors'

const RegularText = styled.span`
    font-family: Heebo;
`

const MediumText = styled.span`
    font-family: Heebo;
    font-weight: 500;
`

const BoldText = styled.span`
    font-family: Heebo;
    font-weight: bold;
`

const Title = styled(BoldText)`
    color: ${Colors.PRIMARY_TEXT};
    font-size: 24px;
`

const ContentTitle = styled.h1`
    font-family: Heebo;
    font-size: 30px;
`

const ContentDescription = styled.p`
    font-family: Heebo;
    white-space: pre-line;
`

const MarkerMapContent = styled.div`
    font-family: Heebo;
    font-size: 10px;
    color: ${Colors.APP_WHITE};
`

const MarkerMapTitle = styled.div`
    font-weight: bold;
    font-family: Heebo;
    margin-left: 5px;
    font-size: 15px;
    color: ${Colors.APP_WHITE};
`

const Texts = {
    RegularText,
    MediumText,
    BoldText,
    Title,
    ContentTitle,
    ContentDescription,
    MarkerMapTitle,
    MarkerMapContent,
}
export default Texts
