import {
    useEffect,
    useSingles,
    useDispatch,
    useCallback,
    useMenuItems,
    useWindowSize,
} from 'services/hooks'
import {React, styled} from 'common'
import {Images} from 'appearance'
import Drawer from 'components/Page/Header/Drawer'
import {getSingles, getMenuItems} from 'store/actions'
import HeaderControllers from 'components/Page/Header/HeaderControllers'

const Header = ({drawerVisible, toggleDrawer}) => {
    const menuItems = useMenuItems()
    const singles = useSingles()
    const dispatch = useDispatch()
    const height = useWindowSize().height
    const initializeMenuItems = useCallback(() => {
        if (!singles) {
            dispatch(getSingles())
        }
        dispatch(getMenuItems())
    }, [dispatch, singles])

    useEffect(() => initializeMenuItems(), [initializeMenuItems])

    return (
        <Container>
            <HeaderControllers
                toggleDrawer={toggleDrawer}
                menuItems={menuItems}
            />

            <Logo src={Images.logo} />

            <Drawer
                height={height}
                open={drawerVisible}
                toggleDrawer={toggleDrawer}
                main={menuItems?.find((item) => item.title === 'ראשי')}
                sites={menuItems?.find((item) => item.title === 'מסלולים')}
                apps={menuItems?.find((item) => item.title === 'אפליקציות')}
            />
        </Container>
    )
}

export default Header

const Container = styled.div`
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Logo = styled.img`
    margin-right: 140px;
    @media (max-width: 1200px) {
        margin-right: 50px;
    }
    @media (max-width: 422px) {
        margin-right: 0px;
    }
    width: 240px;
    height: 96px;
`
