import {React, styled} from 'common'
import {Colors, Icons} from 'appearance'
import _Dropdown from 'components/Page/Header/Dropdown'
import {useNavigate, useMenuItems} from 'services/hooks'
import Sites from 'components/Page/Header/Dropdown/Sites'

const HeaderControlls = ({show = true, children}) => {
    const navigate = useNavigate()
    const menuItems = useMenuItems()

    const getMenuItemChildren = (title) =>
        menuItems?.find((item) => item.title === title)?.children

    return (
        <Container hide={!show}>
            {children}
            <Dropdown
                title="ראשי"
                items={getMenuItemChildren('ראשי')}
                arrow={Icons.menuExpand}
                // width={width}
                withIcon
            />
            <DropdownSites
                title="מסלולים"
                items={getMenuItemChildren('מסלולים')}
                arrow={Icons.menuExpand}
            />
            <Dropdown
                title="אפליקציות"
                width={160}
                items={getMenuItemChildren('אפליקציות')}
                arrow={Icons.menuExpand}
            />
            <Dropdown title="סרטונים" onClick={() => navigate('/videos')} />
            <Dropdown title="כתבות" onClick={() => navigate('/articles')} />
            <Dropdown title="אודות" onClick={() => navigate('/about')} />
        </Container>
    )
}

export default HeaderControlls

const Container = styled.div`
    @media (max-width: 990px) {
        align-items: start;
        flex-direction: column;
    }
    flex: 1;
    flex-wrap: wrap;
    font-size: 16px;
    align-items: center;
    justify-content: space-around;
    display: flex;
    .anticon {
        font-size: 55%;
        padding: 5px;
    }
    .anticon-home {
        font-size: 200%;
        padding: 0;
    }

    .anticon-search {
        margin-left: 20px;
    }
    ${({hide}) => hide && 'display: none;'};
`

const Dropdown = styled(_Dropdown)`
    flex-direction: column;
    &:after {
        width: 0px;
        height: 4px;
        content: '';
        position: absolute;
        left: 50%;
        margin-left: -12px;
        transform: scaleX(0);
        border-radius: 2px;
        background-color: ${Colors.PRIMARY};
        transition: width, transform 0.3s ease;
    }
    &:hover:after {
        transform: scaleX(1);
        width: 24px;
    }
`
const DropdownSites = styled(Sites)`
    flex-direction: column;
    &:after {
        width: 0px;
        height: 4px;
        content: '';
        position: absolute;
        left: 50%;
        margin-left: -12px;
        transform: scaleX(0);
        border-radius: 2px;
        background-color: ${Colors.PRIMARY};
        transition: width, transform 0.3s ease;
    }
    &:hover:after {
        transform: scaleX(1);
        width: 24px;
    }
    padding-right: 20px;
`
