import {Colors} from 'appearance'
import {React, styled} from 'common'

const CustomButton = ({txt, ...props}) => {
    return (
        <RoundButton type="text" {...props}>
            {txt}
        </RoundButton>
    )
}

export default CustomButton

export const RoundButton = styled.button`
    text-align: center;
    height: 70px;
    min-width: 70px;
    border-radius: 50%;
    font-size: 15px;
    border: none;
    margin: 4px;
    background-color: ${Colors.PRIMARY};
    color: ${Colors.APP_WHITE};
    :hover {
        cursor: pointer;
    }
`
