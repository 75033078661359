import {Menu} from 'antd'
import {React, styled} from 'common'
import Sites from 'components/Page/Header/Dropdown/Menu/Sites'

const Regions = ({title, items, arrow, ...props}) => {
    return (
        <Container expandIcon={<></>} triggerSubMenuAction="click">
            {items?.map((item) => Sites({item}))}
        </Container>
    )
}

export default Regions

const Container = styled(Menu)`
    overflow-y: auto;
    overflow-x: hidden;
    height: 568px;
    padding: 10px 10px 0 0;
    border: 1px solid #00000026;
    @media (pointer: none), (pointer: coarse) {
        position: fixed;
        margin-left: -120px;
        height: 350px;
        padding: 10px 10px 120px 0;
        border-bottom: inherit;
    }
    .ant-dropdown-menu-title-content {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
`
