import {React} from 'common'
import Page from 'components/Page/Page'
import {getSingles} from 'store/actions'
import {urlDecode} from 'services/utils/utils'
import {
    useCallback,
    useSingles,
    useLocation,
    useDispatch,
    useState,
    useMemo,
} from 'services/hooks'
import SiteFilter from 'components/Site/SiteFilter'
import SinglesList from 'components/Home/SinglesList'
import SiteMap from 'components/Control/Display/Map/SiteMap'

const Site = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const siteName = urlDecode(location.pathname.split('/').pop())
    const singles = useSingles(siteName)

    const [filteredSingles, setFilteredSingles] = useState(null)

    const getScreenRequiredData = useCallback(() => {
        dispatch(getSingles())
    }, [dispatch])

    const displayedSingles = useMemo(
        () => filteredSingles || singles,
        [filteredSingles, singles]
    )

    return (
        <Page
            pageTitle={siteName}
            screenRequiredData={[singles]}
            getScreenRequiredData={getScreenRequiredData}>
            <SiteMap singles={singles} displayedSingles={displayedSingles} />
            <SiteFilter
                singles={singles}
                setFilteredSingles={setFilteredSingles}
            />
            <SinglesList singles={displayedSingles} noBackground={true} />
        </Page>
    )
}

export default Site
