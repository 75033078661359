window.__TEST__ = true
window.AWS_IMAGES_URL =
    process.env.REACT_APP_AWS_IMAGES_URL ||
    'https://mesangelim.s3.amazonaws.com/'
window.HTTPS = process.env.HTTPS || 'true'
window.API_URL =
    process.env.REACT_APP_BASE_API_URL || `${window.location.origin}/api`
// 'https://mesangelim.codelovers-solutions.com/api'
window.FACEBOOK_APP_ID =
    process.env.REACT_APP_FACEBOOK_APP_ID || '3393347934281418'
window.GOOGLE_MAPS_KEY =
    process.env.REACT_APP_GOOGLE_MAPS_KEY ||
    'AIzaSyA-mc5KD7ZZ8GDP7XSoro___58KTWy5HbI'
