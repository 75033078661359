import {React, styled} from 'common'
import {Colors, Icons} from 'appearance'
import {useState} from 'react'
import {toggleMapFullScreen} from 'services/utils/maps'

const FullscreenButton = ({mapRef}) => {
    const [isFullScreen, setIsFullscreen] = useState(false)

    //note: creating full screen is required because iOS doesn't support fullscreen feature
    const onToggleMapFullScreen = () => {
        toggleMapFullScreen(mapRef)
        setIsFullscreen(!isFullScreen)
    }

    return (
        <Btn onClick={onToggleMapFullScreen}>
            {isFullScreen ? Icons.compress : Icons.expand}
        </Btn>
    )
}

export default FullscreenButton

const Btn = styled.button`
    width: 40px;
    height: 40px;
    background-color: ${Colors.APP_WHITE};
    border: none;
    font-size: 20px;
    padding-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
    &:hover {
        cursor: pointer;
    }
    z-index: 1;
    position: absolute;
`
