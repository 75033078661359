import {React, styled} from 'common'
import {useState} from 'services/hooks'
import {Icons, Colors} from 'appearance'
import ShareButtons from 'components/Single/Share/ShareButtons'

const Share = () => {
    const [showShareBtns, setShowShareBtns] = useState(false)
    return (
        <Dropdown gotClicked={showShareBtns}>
            <ShareControlls onClick={() => setShowShareBtns(!showShareBtns)}>
                {Icons.menuExpand}
                {Icons.share}
            </ShareControlls>
            {showShareBtns && <ShareButtons />}
        </Dropdown>
    )
}

export default Share

const Dropdown = styled.div`
    display: flex;
    flex-direction: column;
    width: 60px;
    height: 28px;
    direction: ltr;
    z-index: 20;
    .anticon-share-alt {
        box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
        flex: 2;
        background-color: ${({gotClicked}) =>
            gotClicked
                ? Colors.SHARE.BACKGROUND_CLICKED
                : Colors.SHARE.BACKGROUND};
        border-right: ${Colors.SHARE.BORDER} solid 0.5px;
        font-size: 100%;
        padding: 6px 11px 6px 6px;
        &:hover {
            cursor: pointer;
            box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
        }
    }
    .anticon-caret-down {
        box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
        flex: 1;
        background-color: ${Colors.SHARE.BACKGROUND};
        font-size: 100%;
        padding: 6px;
        &:hover {
            cursor: pointer;
            box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
        }
    }
`

const ShareControlls = styled.div`
    display: flex;
    border: ${Colors.SHARE.BORDER} solid 0.5px;
    border-radius: 4px;
    flex-direction: row;
    font-size: 14px;
    align-self: stretch;
    flex: 1;
    color: ${Colors.APP_WHITE};
    direction: rtl;
    margin-bottom: 2px;
`
