import {React, styled} from 'common'
import Colors from 'appearance/colors'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js'
import {Line} from 'react-chartjs-2'
import haversineDistance from 'haversine-distance'

import {useRef, useEffect, useCallback} from 'services/hooks'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
)

const options = (title) => {
    return {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        plugins: {
            tooltip: {
                yAlign: 'bottom',
            },
            title: {
                display: true,
                text: title,
            },
        },
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
            },
        },
    }
}

const formatChartData = (yLabel, yData, xLabels) => {
    return {
        labels: xLabels,
        datasets: [
            {
                label: yLabel,
                data: yData,
                borderColor: Colors.APP_BLACK,
                backgroundColor: Colors.SINGLE_HEIGHT_CHART.BACKGROUND,
                yAxisID: 'y',
                fill: true,
                radius: 0,
                tension: 0.8,
            },
        ],
    }
}

const Chart = ({
    title,
    chartData,
    cursorPosition,
    labelsCoords,
    setCursorPosition,
}) => {
    const ref = useRef()

    const triggerTooltip = useCallback(
        (chart) => {
            const {tooltip} = chart
            const {chartArea} = chart
            if (!cursorPosition || !labelsCoords) {
                return null
            }

            let index = labelsCoords.findIndex(
                (coordinate) => cursorPosition === coordinate
            )
            if (index === -1) {
                index = labelsCoords.findIndex(
                    (coordinate) =>
                        haversineDistance(
                            [
                                coordinate[1].toFixed(3),
                                coordinate[0].toFixed(3),
                            ],
                            [
                                cursorPosition.lat.toFixed(3),
                                cursorPosition.lng.toFixed(3),
                            ]
                        ) < 0.001
                )
            }

            if (index !== -1) {
                tooltip.setActiveElements([{datasetIndex: 0, index: index}], {
                    x: chartArea.left + chartArea.right,
                    y: chartArea.top + chartArea.bottom,
                })
            }
        },
        [cursorPosition, labelsCoords]
    )

    const mouseMove = useCallback(
        (ref) => {
            const index = ref?.current?.tooltip?.getActiveElements()[0]?.index
            if (index) {
                setCursorPosition({
                    lat: labelsCoords[index][1],
                    lng: labelsCoords[index][0],
                })
            } else {
                setCursorPosition(null)
            }
        },
        [labelsCoords, setCursorPosition]
    )

    const yLabel = chartData?.y?.label
    const yData = chartData?.y?.data
    const xLabels = chartData?.x

    useEffect(() => {
        const chart = ref.current
        triggerTooltip(chart)
    }, [triggerTooltip])

    return (
        <Container>
            <Line
                onMouseMove={() => mouseMove(ref)}
                onMouseOut={() => setCursorPosition(null)}
                ref={ref}
                options={options(title)}
                data={formatChartData(yLabel, yData, xLabels)}
            />
        </Container>
    )
}

export default Chart

const Container = styled.div`
    border: 1px solid ${Colors.APP_SECONDARY};
    background-color: ${Colors.APP_WHITE};
    flex: 1;
`
