import {React, styled} from 'common'
import {useNavigate} from 'services/hooks'
import {Images, Colors, Texts} from 'appearance'
import PinImage from 'components/Control/Display/PinImage'
import {navigateToSinglePage} from 'services/utils/utils'
import Difficulty from 'components/Control/Display/SingleCard/Difficulty'

const SingleCard = ({single}) => {
    const navigate = useNavigate()

    const CardClickedHandler = () => {
        navigateToSinglePage(navigate, single)
    }
    const {technicalDifficulty, title, image} = single || {}
    const siteTitle = single.site.title || {}

    return (
        <Card onClick={CardClickedHandler}>
            <HalfCardContainer>
                <SingleImage
                    alt="could't find single image"
                    src={window.AWS_IMAGES_URL + image}
                />
            </HalfCardContainer>
            <HalfCardContainer>
                <PinImage
                    className="pinImage"
                    alt="couldn't find pin image"
                    src={Images.pins[technicalDifficulty]}
                    activeAnimation={false} //instead active animation through Card
                />
                <SiteTitle>{siteTitle}</SiteTitle>
                <SingleTitle>{title}</SingleTitle>
                <Difficulty difficulty={technicalDifficulty} />
            </HalfCardContainer>
        </Card>
    )
}

export default SingleCard

const Card = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 263px;
    height: 390px;
    border-radius: 12px;
    background-color: ${Colors.APP_WHITE};
    margin: 50px 0px;
    cursor: pointer;
    overflow: hidden;
    &:hover {
        box-shadow: 4px 8px 20px rgb(1 1 1 / 10%);
    }
    &:hover .pinImage {
        animation-name: jump;
        animation-duration: 0.5s;
    }
    @keyframes jump {
        0% {
            margin: -28px 0px 28px 0px;
        }
        25% {
            margin: -52px 0px 52px 0px;
        }
        50% {
            margin: -28px 0px 28px 0px;
        }
        75% {
            margin: -40px 0px 40px 0px;
        }
        100% {
            margin: -28px 0px 28px 0px;
        }
    }
`

const HalfCardContainer = styled.div`
    height: 50%;
    display: flex;
    flex-direction: column;
`

const SingleImage = styled.img`
    border-bottom: 1px solid #bfc8cb;
    width: 100%;
    height: 100%;
`

const SiteTitle = styled(Texts.RegularText)`
    font-size: 17px;
`

const SingleTitle = styled(Texts.RegularText)`
    font-size: 20px;
`
