import {React, styled} from 'common'
import Images from 'appearance/images'
import Colors from 'appearance/colors'

const CenterMap = ({onClick}) => {
    return (
        <Btn onClick={onClick}>
            <BikeImg src={Images.map.Route} alt="could't find Image" />
        </Btn>
    )
}

export default CenterMap

const Btn = styled.button`
    width: 40px;
    height: 40px;
    background-color: ${Colors.APP_WHITE};
    border: none;
    font-size: 20px;
    margin-bottom: 10px;
    &:hover {
        cursor: pointer;
    }
`

const BikeImg = styled.img``
