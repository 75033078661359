import {Images} from 'appearance'
import {React, styled} from 'common'
import PinImage from 'components/Control/Display/PinImage'

const SingleStartMarker = ({marker, onClick}) => {
    return (
        <Container onClick={onClick}>
            <PinImage src={Images.pins[marker?.difficulty]} alt="marker" />
        </Container>
    )
}

export default SingleStartMarker

const Container = styled.div``
