import {Page} from 'components/Page'
import {React, styled} from 'common'
import {getVideos} from 'store/actions'
import Video from 'components/Videos/Video'
import {useCallback, useDispatch, useVideos} from 'services/hooks'

const Videos = () => {
    const dispatch = useDispatch()
    const videos = useVideos()

    const getScreenRequiredData = useCallback(() => {
        dispatch(getVideos())
    }, [dispatch])

    const getSrc = (src) =>
        src.match(
            // eslint-disable-next-line no-useless-escape
            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/
        )
    return (
        <Page
            title="סרטונים"
            screenRequiredData={[videos]}
            getScreenRequiredData={getScreenRequiredData}>
            <VideosList>
                {videos?.map((video, index) => (
                    <Video
                        key={index}
                        src={getSrc(video.content)[0]}
                        date={video.created}
                        title={video.title}
                    />
                ))}
            </VideosList>
        </Page>
    )
}

export default Videos

const VideosList = styled.div`
    display: flex;
    flex-direction: column;
`
