import {React, styled} from 'common'
import {useEffect} from 'services/hooks'

const FacebookComments = (url) => {
    useEffect(() => {
        window.FB?.XFBML?.parse()
    }, [])
    return (
        <Container
            className="fb-comments"
            data-href={'https://developers.facebook.com/docs/plugins/' + url}
            data-numposts="5"
        />
    )
}

export default FacebookComments

const Container = styled.div``
