import {React, styled} from 'common'
import Page from 'components/Page/Page'
import Row from 'components/Control/Display/Row'
import {Texts} from 'appearance'

const Terms = () => {
    return (
        <Page title="תנאי שימוש">
            <Content>
                <Row>
                    ברוכים הבאים לאתר "מסנגלים" (להלן: <Bold>"האתר"</Bold>).
                    השימוש והגלישה של כל משתמש באתר כפופים להוראות ולתנאים
                    המפורטים להלן (להלן: <Bold>"תנאי השימוש"</Bold>), ללא כל
                    התניה, ללא כל הסתייגות ובכפוף להוראות כל דין.
                </Row>
                <Row>
                    השימוש בתכנים המוצגים באתר, לרבות מסלולים, כתבות, תמונות,
                    סרטונים, קישורים לאתרים אחרים, אפליקציות וכיוצ"ב, מוצעים
                    למשתמש כפי שהם והינם באחריותו הבלעדית של המשתמש.
                </Row>
                <Row>
                    המשתמש מתחייב בהתחייבות בלתי חוזרת, כי לא תהיה לו כל טענה
                    ו/או דרישה ו/או תביעה מכל מין וסוג שהוא, כלפי האתר ו/או מי
                    מטעמו.
                </Row>
                <Row>
                    מובהר כי בעלי האתר, או מי מטעמם, אינם אחראים לשימוש שנעשה
                    בתכנים הנמצאים באתר, וכי אין בתכנים אלה להטיל אחריות על בעלי
                    האתר בקשר עם כל פגיעה ו/או אובדן ו/או נזק של המשתמש. אין
                    לייחס להמלצות ו/או לתיאורים ו/או למסלולים ו/או לתכנים
                    המוצגים באתר, כל תוקף מחייב.
                </Row>
                <Row>
                    לאור העובדה שמסלולי הרכיבה המוצגים באתר נמצאים בטבע, המשתמש
                    מודע לכך, כי תוואי המסלולים עשוי להשתנות, כתוצאה מהשפעת תנאי
                    מזג אוויר, כריתת עצים ביערות, מצב ביטחוני וכיוצ"ב. לפיכך
                    המשתמש יהיה האחראי הבלעדי לכל שימוש שהוא עושה באתר ובתכנים
                    המוצגים בו, הוא נדרש לבדוק האם חל שינוי בתוואי המסלול, טרם
                    יחליט להשתמש בו.
                </Row>
                <Row>
                    התכנים המוצגים באתר הינם בגדר הצעה כללית. בעלי האתר אינם
                    נושאים בכל אחריות שהיא לתוצאות ישירות ועקיפות, שיגרמו
                    למשתמש, או לצד שלישי. האחריות המלאה והבלעדית לפעולותיו
                    ולתוצאותיו תחול על המשתמש בלבד. בעלי האתר, או מי מטעמם, לא
                    יישאו באחריות לכל נזק, הפסד, אובדן מכל סוג, אי נוחות, תשלום,
                    הוצאה, עוגמת נפש וכיוצ"ב, תוצאות ישירות, או עקיפות, שיגרמו
                    למשתמש ולמי מטעמו ולכל צד שלישי בשל שימוש בתכנים והסתמכות
                    עליהם, כמו גם יישומי תוכנה ואמצעים וכל כלי אחר הניתן לעשות
                    בו שימוש באמצעות האתר.
                </Row>
                <Row>
                    בעלי האתר אינם אחראים לתכנים המופיעים באתרים חיצוניים
                    המקושרים לאתר. הקישורים לאתרים חיצוניים נועדו לנוחות המשתמש
                    בלבד ואין האתר, או מי מטעמו, כל קשר ואחריות לתכנים המפורסמים
                    בהם, לחוקיותם, לתקינותם ולתפעולם.
                </Row>
                <Row>השימוש באתר הינו למטרות אישיות ופרטיות בלבד.</Row>
                <Row>
                    המשתמש מתחייב לשפות ולפצות את בעלי האתר וכל מי מטעמם, בגין
                    כל נזק, הפסד, הוצאה, תביעה ושכר טרחת עו"ד, שיגרמו להם כתוצאה
                    מהפרת תנאי השימוש המפורטים לעיל.
                </Row>
                <Row>
                    הדין שיחול על תנאי השימוש הינו דיני מדינת ישראל. כל חילוקי
                    הדעות בכל דבר ועניין הנוגע לתנאי השימוש, יובא להכרעת בית
                    המשפט בעיר רחובות, אשר לו תהיה סמכות שיפוט בלעדית.
                </Row>
                <Row>
                    בעלי האתר רשאים בכל עת וללא כל הודעה מוקדמת, לשנות את תנאי
                    השימוש באתר, ושינוי זה יחייב את המשתמש מיד עם פרסומו באתר.
                </Row>
                <Row>
                    בעלי האתר רשאים בכל עת, בהתאם לשיקול דעתם הבלעדי, לשנות
                    תכנים, לעדכן, לסגור את האתר, ללא מתן הודעה מראש.
                </Row>
            </Content>
        </Page>
    )
}

export default Terms

const Content = styled(Texts.ContentDescription)`
    margin: 40px auto auto auto;
    max-width: 800px;
    width: 100%;
    padding-bottom: 50px;
`

const Bold = styled.u`
    font-weight: bold;
`
