import {
    useState,
    useSites,
    useSingles,
    useRegions,
    useDispatch,
    useCallback,
    useNavigate,
} from 'services/hooks'
import {React} from 'common'
import {Colors} from 'appearance'
import {Page} from 'components/Page'
import Hero from 'components/Home/Hero'
import SinglesList from 'components/Home/SinglesList'
import {getFeaturedSingle} from 'services/utils/single'
import urlSingleTitle from 'services/utils/urlSingleTitle'
import FeaturedSingle from 'components/Home/FeaturedSingle'
import {getSites, getSingles, getRegions} from 'store/actions'

const Home = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const singles = useSingles()
    const sites = useSites()
    const regions = useRegions()
    const singleTitles = urlSingleTitle()
    const featuredSingle = getFeaturedSingle(singles)

    const [filteredSingles, setFilteredSingles] = useState(null)

    if (singleTitles) {
        navigate(`/single/${singleTitles}`)
        window.location.reload(false)
    }

    const getScreenRequiredData = useCallback(() => {
        dispatch(getSingles())
        dispatch(getSites())
        dispatch(getRegions())
    }, [dispatch])

    const singlesWithoutFeaturedSingle = singles?.filter(
        (single) => !single.featured
    )

    return (
        <Page
            hero
            getScreenRequiredData={getScreenRequiredData}
            screenRequiredData={[singles, sites, regions]}>
            <Hero setFilteredSingles={setFilteredSingles} singles={singles} />
            {!filteredSingles && <FeaturedSingle single={featuredSingle} />}
            <SinglesList
                singles={filteredSingles || singlesWithoutFeaturedSingle}
                backgroundColor={Colors.APP_GREY_SECTION}
            />
        </Page>
    )
}

export default Home
