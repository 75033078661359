import main from 'assets/images/main.jpg'
import logo from 'assets/images/logo.jpg'
import glow from 'assets/images/glow.png'
import bigLogo from 'assets/images/bigLogo.png'
import pinRed from 'assets/images/pins/hard.png'
import pinGreen from 'assets/images/pins/easy.png'
import thumbtack from 'assets/images/thumbtack.png'
import bike from 'assets/images/map/small_bike.png'
import pinYellow from 'assets/images/pins/normal.png'
import endRoute from 'assets/images/map/end_route.png'
import startRoute from 'assets/images/map/start_route.png'

const images = {
    main,
    logo,
    bigLogo,
    glow,
    thumbtack,

    pins: {
        Easy: pinGreen,
        Normal: pinYellow,
        Hard: pinRed,
    },

    map: {
        Start: startRoute,
        End: endRoute,
        Route: bike,
    },
}

export default images
