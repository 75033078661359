import {Colors, Styles} from 'appearance'
import styled from 'styled-components'

const Section = ({backgroundColor, children, padded, ...p}) => {
    return (
        <Container backgroundColor={backgroundColor}>
            <InnerContainer padded={padded} {...p}>
                {children}
            </InnerContainer>
        </Container>
    )
}

export default Section

const Container = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    background-color: ${({backgroundColor}) =>
        backgroundColor || Colors.APP_WHITE};
`

const InnerContainer = styled.div`
    max-width: ${Styles.SCREEN_WIDTH}px;
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    @media (max-width: 1200px) {
        max-width: 961px;
    }
    @media (max-width: 990px) {
        max-width: 760px;
    }
    padding: ${({padded}) =>
        padded ? `${Styles.ROW_PADDING} ${Styles.SIDE_PADDING}` : '0px 0px'};
`
