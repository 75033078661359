/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from 'react'

export * from 'react'
export * from 'store/app/app.uses'
export * from 'store/hero/hero.uses'
export {useDispatch} from 'react-redux'
export * from 'store/header/header.uses'
export {useLocation} from 'react-router'
export * from 'store/singles/singles.uses'
export {
    Navigate,
    Routes,
    Route,
    BrowserRouter,
    Link,
    useNavigate,
} from 'react-router-dom'
// export * from 'react-table'

// Hook - const size = useWindowSize()
export const useWindowSize = () => {
    const isClient = typeof window === 'object'

    const getSize = () => {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
        }
    }

    const [windowSize, setWindowSize] = useState(getSize)

    useEffect(() => {
        if (!isClient) {
            return false
        }

        function handleResize() {
            setWindowSize(getSize())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, []) // Empty array ensures that effect is only run on mount and unmount

    return windowSize
}

// hook to change url params and listen to url params changes

const getUrlParamsObject = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const params = {}
    searchParams.forEach((v, k) => (params[k] = v))
    // console.log('getUrlParamsObject - params:', params)
    return params
}

const updateUrl = (key, val, refresh = false) => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set(key, val)
    const newUrl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        '?' +
        searchParams.toString()

    if (window.history.pushState) {
        window.history.pushState({path: newUrl}, '', newUrl)
    }
    //console.log('changeUrl returns: ', searchParams.toString())
    return searchParams.toString()
}

export const useUrlParams = () => {
    const [urlParams, setUrlParams] = useState({})

    useEffect(() => {
        function handleUrlChange() {
            setUrlParams(getUrlParamsObject())
        }
        setUrlParams(getUrlParamsObject())
        window.addEventListener('popstate', handleUrlChange)

        return () => window.removeEventListener('popstate', handleUrlChange)
    }, [])

    // change url by adding or updating the key param with values
    const setUrlParam = (key, value) => {
        updateUrl(key, value)
        window.dispatchEvent(new Event('popstate'))
    }

    // console.log('history at the end:', history)
    return [urlParams, setUrlParam]
}

export const useIsMobile = () => {
    return useWindowSize().width <= 768
}
