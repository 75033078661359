import {Colors} from 'appearance'
import {React, styled} from 'common'
import {useNavigate} from 'services/hooks'
import {urlEncode} from 'services/utils/utils'
import Date from 'components/Control/Display/Date'

const SinglesResult = ({singles}) => {
    const navigate = useNavigate()
    return (
        <Container>
            {singles?.map((single, index) => (
                <Card key={index}>
                    <FlexCol>
                        <Title
                            onClick={() =>
                                navigate(
                                    '/single/' +
                                        urlEncode(single.site.region.title) +
                                        '/' +
                                        urlEncode(single.site.title) +
                                        '/' +
                                        urlEncode(single.title)
                                )
                            }>
                            {single.title}
                        </Title>
                        <Date date={single.date} />
                    </FlexCol>
                    <SingleImage
                        src={window.AWS_IMAGES_URL + single.image}
                        alt="couldn't show image :("
                    />
                </Card>
            ))}
        </Container>
    )
}

export default SinglesResult

const Container = styled.div`
    display: flex;
    flex-flow: column;
    text-align: center;
    justify-content: center;
`

const Card = styled.div`
    display: flex;
    flex-flow: row;
    align-self: center;
    align-items: center;
    width: 800px;
    height: 188px;
    margin: 50px 0px;
    overflow: hidden;
    border-radius: 45px;
    background-color: ${Colors.APP_WHITE};
    box-shadow: 0 2px 5px rgb(1 1 1 / 10%);
    &:hover {
        box-shadow: 0 0px 20px rgb(1 1 1 / 20%);
    }
    transition: all 0.3s ease-in-out 0s;
    @media (max-width: 800px) {
        flex-flow: column-reverse;
        height: 300px;
        padding: 20px 0;
    }
`

const SingleImage = styled.img`
    @media (max-width: 800px) {
        border-radius: 20px;
    }
    width: 30%;
    flex: 1;
`
const Title = styled.h2`
    @media (max-width: 800px) {
        margin: 15px 0;
    }
    cursor: pointer;
    color: ${Colors.PRIMARY};
`

const FlexCol = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
`
