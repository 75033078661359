import {React, styled} from 'common'
import Page from 'components/Page/Page'
import {getArticles} from 'store/actions'
import Article from 'components/Articles/Article'
import {useCallback, useDispatch, useArticles} from 'services/hooks'

const Articles = () => {
    const dispatch = useDispatch()
    const articles = useArticles()

    const getScreenRequiredData = useCallback(() => {
        dispatch(getArticles())
    }, [dispatch])

    return (
        <Page
            title="כתבות"
            getScreenRequiredData={getScreenRequiredData}
            screenRequiredData={[articles]}>
            <ArticlesList>
                {articles?.map((article, index) => (
                    <Article
                        key={index}
                        content={article.content}
                        date={article.created}
                        title={article.title}
                        id={article._id}
                        preview={true}
                    />
                ))}
            </ArticlesList>
        </Page>
    )
}

export default Articles

const ArticlesList = styled.div`
    display: flex;
    flex-direction: column;
`
