import {React} from 'common'
import {Routes, Route, BrowserRouter as Router} from 'react-router-dom'
import Home from 'screens/Home'
import NewSingles from 'screens/NewSingles'
import About from 'screens/About'
import Terms from 'screens/Terms'
import Videos from 'screens/Videos'
import Articles from 'screens/Articles'
import Site from 'screens/Site'
import SearchResults from 'screens/SearchResults'
import Single from 'screens/Single'
import Article from 'screens/Article'

const NavigationRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} exact />
                <Route path="/new" element={<NewSingles />} />
                <Route path="/about" element={<About />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/videos" element={<Videos />} />
                <Route path="/articles" element={<Articles />} />
                <Route
                    path="/site/:regionTitle/:siteTitle"
                    element={<Site />}
                />
                <Route path="/search" element={<SearchResults />} />
                <Route
                    path="/single/:regionTitle/:siteTitle/:singleTitle"
                    element={<Single />}
                />
                <Route path="/single/:singleTitle" element={<Single />} />
                <Route path="/article/:articleTitle" element={<Article />} />
            </Routes>
        </Router>
    )
}

export default NavigationRouter
