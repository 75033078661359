import {useSelector} from 'react-redux'
import {findSingleByName} from 'services/utils/single'
import {urlEncode} from 'services/utils/utils'

export const useSingles = (siteName) =>
    useSelector((state) => {
        const {singles} = state.singles
        if (siteName) {
            return singles?.filter(
                (single) => urlEncode(single.site.title) === siteName
            )
        }
        return singles
    })

export const useSingle = (singleName) => {
    return useSelector((state) => {
        const {singles} = state.singles
        console.log('singles from use single ', {singles})
        return findSingleByName(singleName, singles)
    })
}
