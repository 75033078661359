import {I18n} from 'i18n-js'
import en from './locales/en'
import he from './locales/he'

let activeLanguage = localStorage.getItem('@markidim:language')
if (!activeLanguage) {
    localStorage.setItem('@web_init:language', 'he')
    activeLanguage = 'he'
}
const i18n = new I18n({he, en})
i18n.locale = activeLanguage
i18n.fallbacks = true

export const setLocale = (lang) => {
    if (i18n.locale === lang) {
        return
    }
    i18n.locale = lang
    if (lang === 'he') {
        localStorage.setItem('@web_init:language', 'he')
    } else {
        localStorage.setItem('@web_init:language', 'en')
    }
    // window.location.reload(false);
}
export default i18n
