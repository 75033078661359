import {Menu} from 'antd'
import {v4 as uuidv4} from 'uuid'
import {React, styled} from 'common'
import {getSingles} from 'store/actions'
import {Colors, Icons} from 'appearance'
import Amount from 'components/Page/Header/Amount'
import Site from 'components/Page/Header/Dropdown/Menu/Site'
import Title from 'components/Page/Header/Dropdown/Menu/Title'
import {useSingles, useCallback, useDispatch} from 'services/hooks'

const SubMenuContainer = ({item, ...props}) => {
    const dispatch = useDispatch()
    const singles = useSingles()
    useCallback(() => {
        if (!singles) {
            dispatch(getSingles())
        }
    }, [dispatch, singles])
    return (
        <Container
            key={uuidv4()}
            popupOffset={[-390, -14]}
            icon={Icons.subMenuExpand}
            title={
                <Title title={item.title}>
                    <Amount amount={item.children.length} />
                </Title>
            }>
            {item?.children?.map((site) =>
                Site({
                    regionTitle: item.title,
                    siteTitle: site.title,
                    siteId: site.link,
                })
            )}
        </Container>
    )
}

export default SubMenuContainer

const Container = styled(Menu.SubMenu)`
    display: flex;
    flex-flow: row-reverse;
    flex: 1;
    width: 100%;
    text-align: right;
    align-self: center;
    justify-content: space-between;
    padding-bottom: 10px;

    .ant-dropdown-menu-submenu-title {
        display: flex;
        flex: 1;
        justify-content: space-between;
        padding-right: 12px;
        align-items: center;
    }
    .anticon {
        color: ${Colors.PRIMARY};
    }
`
