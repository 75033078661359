import {React, styled} from 'common'
import {useState} from 'services/hooks'
import StyledSelect from 'components/Home/Hero/Select'
import Section from 'components/Page/Section'

const getFilteredSingleList = (singles, difficulty) => {
    if (difficulty) {
        return singles?.filter(
            (single) => single.technicalDifficulty === difficulty
        )
    }
    return null
}

const SiteFilter = ({singles, setFilteredSingles}) => {
    const [difficulty, setDifficulty] = useState()
    const onChangeDifficulty = (difficulty) => {
        setDifficulty(difficulty)
        const filteredSingles = getFilteredSingleList(singles, difficulty)
        setFilteredSingles(filteredSingles)
    }

    return (
        <Container>
            <DifficultyLevel
                title="כל הרמות"
                items={[
                    {text: 'כל הרמות', href: null},
                    {text: 'קל', href: 'Easy'},
                    {text: 'בינוני', href: 'Normal'},
                    {text: 'קשה', href: 'Hard'},
                ]}
                onChange={onChangeDifficulty}
                value={difficulty}
            />
        </Container>
    )
}

export default SiteFilter

const Container = styled(Section)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const DifficultyLevel = styled(StyledSelect)`
    flex: 0;
`
