const defaultState = {
    sites: [],
    regions: [],
    siteId: null,
    regionId: null,
    technicalDifficulty: null,
}

const HeroReducer = (state = defaultState, action) => {
    const actionObject = {
        SET_REGION_ID: () => {
            return {...state, regionId: action.regionId}
        },
        SET_SITE_ID: () => {
            return {...state, siteId: action.siteId}
        },
        SET_DIFFICULTY: () => {
            return {...state, technicalDifficulty: action.difficulty}
        },
        SET_SITES: () => {
            return {...state, sites: action.sites}
        },
        SET_REGIONS: () => {
            return {...state, regions: action.regions}
        },
        SET_FILTER: () => {
            return {
                ...state,
                search: action.search,
                technicalDifficulty: action.difficulty,
                siteId: action.siteId,
                regionId: action.regionId,
                showFeaturedSingle: false,
            }
        },
    }
    const actionFunc = actionObject[action?.type]
    if (actionFunc) {
        return actionFunc(action)
    }

    return state
}

export default HeroReducer
