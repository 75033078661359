import {React, styled} from 'common'
import {Texts} from 'appearance'

const InfoWindowSubTitle = ({text}) => {
    if (text) {
        return <Text> {text} </Text>
    }
    return null
}

export default InfoWindowSubTitle

const Text = styled(Texts.MarkerMapContent)``
