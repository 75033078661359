import {Colors} from 'appearance'
import {React, styled} from 'common'
import {useNavigate} from 'services/hooks'
import HeaderControlls from 'components/Page/Header/HeaderControlls'

const Drawer = ({open, toggleDrawer, main, sites, apps, height = 540}) => {
    const navigate = useNavigate()

    if (!open) {
        return null
    }

    return (
        <>
            <Wrapper onClick={toggleDrawer} />
            <Container height={height}>
                <HeaderControlls
                    mobile={true}
                    main={main}
                    sites={sites}
                    apps={apps}>
                    <Home onClick={() => navigate('/', {replace: true})}>
                        בית
                    </Home>
                </HeaderControlls>
            </Container>
        </>
    )
}

export default Drawer

const Container = styled.div`
    z-index: 2;
    position: fixed;
    top: 140px;
    bottom: 0;
    right: 0;
    height: auto;
    width: 100%;
    @keyframes slide {
        0% {
            height: 0px;
        }
        100% {
            height: ${({height}) => height - 108}px;
        }
    }
    animation-name: slide;
    animation-duration: 1s;
    background-color: ${Colors.DRAWER.BACKGROUND};
    background-size: cover;
    display: flex;
    flex-direction: column;
    margin-top: -29px;
    overflow-y: scroll;
`

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    background-color: #0000001c;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 136px 0;
    overflow: scroll;
`

const Home = styled.div`
    font-size: 16px;
    padding-right: 20px;
`
