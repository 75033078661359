import {Colors} from 'appearance'
import {React, styled} from 'common'

const PinImage = ({
    activeAnimation = true,
    src,
    alt = "couldn't find pin image",
}) => {
    return (
        <Container alt={alt} src={src} activeAnimation={activeAnimation}>
            <Image alt={alt} src={src} className="pinImage" />
        </Container>
    )
}

export default PinImage

const Container = styled.div`
    cursor: pointer;
    &:hover .pinImage {
        animation-name: jump;
        animation-duration: 0.4s;
    }
    @keyframes jump {
        0% {
            margin: -28px 0px 28px 0px;
        }
        25% {
            margin: -52px 0px 52px 0px;
        }
        50% {
            margin: -28px 0px 28px 0px;
        }
        75% {
            margin: -40px 0px 40px 0px;
        }
        100% {
            margin: -28px 0px 28px 0px;
        }
    }
    color: ${Colors.APP_WHITE};
    align-self: center;
    margin: -28px 0px 28px 0px;
    ${({activeAnimation}) => activeAnimation && `padding-left: 50px;`}
`

const Image = styled.img``
