import {React, styled} from 'common'
import CenterMap from 'components/Control/Display/Map/CenterMap'
import CurrentLocation from 'components/Control/Display/Map/CurrentLocation'

const Controlls = ({mapObject, mapCenter}) => {
    if (!mapObject) {
        return null
    }
    const {map} = mapObject
    const centerMapHandler = () => {
        map.setCenter(mapCenter)
    }

    const centerCurrentLocationHandler = () => {
        const handleLocationError = (error) => {
            const locationError = document.getElementById('locationError')
            switch (error.code) {
                case 0:
                    locationError.innerHTML =
                        'There was an error while retrieving your location: ' +
                        error.message
                    break
                case 1:
                    locationError.innerHTML =
                        "The user didn't allow this page to retrieve a location."
                    break
                case 2:
                    locationError.innerHTML =
                        'The browser was unable to determine your location: ' +
                        error.message
                    break
                case 3:
                    locationError.innerHTML =
                        'The browser timed out before retrieving the location.'
                    break
                default:
                    locationError.innerHTML =
                        'Unknown locationError' + error.message
            }
        }

        const handleGotLocation = (position) => {
            map.setCenter({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            })
        }

        if (!navigator.geolocation) {
            alert('HTML5 Geolocation is not supported in your browser.')
            return
        }

        navigator.geolocation.getCurrentPosition(
            handleGotLocation,
            handleLocationError
        )
    }
    return (
        <Container>
            <CenterMap onClick={centerMapHandler} />
            <CurrentLocation onClick={centerCurrentLocationHandler} />
        </Container>
    )
}

export default Controlls

const Container = styled.div`
    position: absolute;
    bottom: 187px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 10px;
`
