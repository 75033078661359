import {React, styled} from 'common'
import Colors from 'appearance/colors'
import {PoweroffOutlined} from '@ant-design/icons'
import {useNavigate} from 'services/hooks'
const LoginIcon = () => {
    const navigate = useNavigate()
    return (
        <Login>
            <PoweroffOutlined
                onClick={() => navigate('/login')}
                title="התחבר"
            />
        </Login>
    )
}

export default LoginIcon

const Login = styled.button`
    background-color: transparent;
    color: transparent;
    border-color: transparent;
    &:hover {
        cursor: pointer;
    }
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 15px;
    color: ${Colors.PRIMARY};
    padding-right: 35px;
    @media (max-width: 1200px) {
        padding-right: 39px;
        margin-left: 16px;
    }
`
