import {useSelector} from 'react-redux'

export const useAuth = () => useSelector((state) => state.app.auth)

export const useUserName = () => useSelector((state) => state.app.userName)

export const useCancelPadding = () =>
    useSelector((state) => state.app.cancelPadding)

export const useShowFeaturedSingle = () =>
    useSelector((state) => state.app.showFeaturedSingle)

export const useArticles = () => useSelector((state) => state.app.articles)

export const useVideos = () => useSelector((state) => state.app.videos)
