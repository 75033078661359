import {Colors} from 'appearance'
import {Spinner} from 'reactstrap'
import {React, styled} from 'common'
import {useSingles} from 'services/hooks'

const NoSingle = () => {
    const singles = useSingles()
    return <Container>{singles ? 'לא נמצאו תוצאות' : <Spinner />}</Container>
}

export default NoSingle

const Container = styled.div`
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 90px;
    border-radius: 20px;
    background-color: ${Colors.APP_WHITE};
    margin: 50px 5px;
    &:hover {
        box-shadow: 4px 8px 20px rgb(1 1 1 / 10%);
    }
`
