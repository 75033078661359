import texts from './texts'
import fonts from './fonts'
import icons from './icons'
import colors from './colors'
import images from './images'
import styles from './styles'

export const Texts = texts
export const Fonts = fonts
export const Icons = icons
export const Colors = colors
export const Styles = styles
export const Images = images
