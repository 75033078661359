import {Colors, Texts} from 'appearance'
import {React, styled, i18n} from 'common'
import {useNavigate} from 'services/hooks'
import {getHebrewHoursRange, navigateToSinglePage} from 'services/utils/utils'
import InfoWindowSubTitle from './InfoWindow/InfoWindowSubTitle'

const formatSingleShapeText = (shape) => {
    if (shape === 'Not Round') {
        return ' לא מעגלי'
    } else if (shape) {
        return ' מעגלי'
    }
    return null
}

const InfoWindow = ({marker}) => {
    const navigate = useNavigate()

    if (!marker) {
        return null
    }

    const {
        title: singleTitle,
        image,
        siteTitle,
        difficulty,
        duration,
        type,
        shape,
    } = marker

    return (
        <Container
            onClick={() => {
                navigateToSinglePage(navigate, marker)
            }}>
            <Image
                src={window.AWS_IMAGES_URL + image}
                alt="single image not found :("
            />
            <SingleDescription>
                {singleTitle && <SingleTitle>{singleTitle}</SingleTitle>}
                <Line />
                <InfoWindowSubTitle text={siteTitle} />
                {difficulty && (
                    <Difficulty>
                        {i18n.t('difficulty.' + difficulty)}
                    </Difficulty>
                )}
                <InfoWindowSubTitle text={getHebrewHoursRange(duration)} />
                <InfoWindowSubTitle text={type} />
                <InfoWindowSubTitle text={formatSingleShapeText(shape)} />
            </SingleDescription>
        </Container>
    )
}

export default InfoWindow

const Container = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: fit-content;
    height: fit-content;
    background-color: white;
    z-index: 1000;
    border-radius: 5px;
    overflow: hidden;
    position: absolute;
    top: 60px;
    left: 10px;
`

const SingleDescription = styled.div`
    flex-direction: column;
    margin-right: 10px;
    margin-top: 10px;
    position: absolute;
`

const SingleTitle = styled(Texts.MarkerMapTitle)``

const Line = styled.div`
    content: '';
    border-radius: 2px;
    width: 35px;
    height: 4px;
    margin: 10px 0;
    background: ${Colors.PRIMARY};
`

const Difficulty = styled(Texts.MarkerMapContent)``

const Image = styled.img`
    filter: brightness(0.8);
    width: 200px;
    height: 150px;
`
