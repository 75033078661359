import {Dropdown} from 'antd'
import {Colors} from 'appearance'
import {React, styled} from 'common'
import Menu from 'components/Page/Header/Dropdown/Menu/Regions'

const Sites = ({title, items, arrow, ...props}) => {
    return (
        <Dropdown
            overlay={Menu({title, items, arrow, ...props})}
            overlayStyle={{
                textAlign: 'right',
            }}
            trigger={['click']}
            placement="bottom"
            {...props}>
            <StyledLink>
                {title}
                {arrow}
            </StyledLink>
        </Dropdown>
    )
}

export default Sites

const StyledLink = styled.a`
    align-self: right;
    color: ${Colors.SECONDARY};
    padding: 5px;
    position: relative;
    line-height: 70px;
    &:hover {
        color: ${Colors.PRIMARY};
    }
`
