export const isNonEmptyArrays = (arrs) =>
    arrs.every((arr) => Array.isArray(arr) && arr.length > 0)

export const urlEncode = (text = '') => text.replaceAll(/,| /g, '-')

export const urlDecode = (text) => decodeURIComponent(text)

export const getHebrewHoursRange = (hours) => {
    if (!hours) {
        return
    }
    if (hours.indexOf('-') > -1) {
        const hoursArr = hours.split('-')
        const hebrewHoursArr = hoursArr?.map((key) => getHebrewHour(key))
        return hebrewHoursArr.join(' עד ')
    }
    return getHebrewHour(hours)
}

const getHebrewHour = (hour) => {
    const doesContainHalf = hour.indexOf('.5') > -1
    if (doesContainHalf) {
        hour = hour.replace('.5', '')
    }

    const hoursTranslations = {
        '1H': 'שעה',
        '2H': 'שעתיים',
        '3H': 'שלוש שעות',
        '4H': 'ארבע שעות',
        '5H': 'חמש שעות',
        '6H': 'שש שעות',
        '7H': 'שבע שעות',
        '8H': 'שמונה שעות',
        '9H': 'תשע שעות',
        '10H': 'עשר שעות',
        '11H': 'אחת עשרה שעות',
        '12H': 'שתים עשרה שעות',
        '13H': 'שלוש עשרה שעות',
        '14H': 'ארבע עשרה שעות',
        '15H': 'חמש עשרה שעות',
        '16H': 'שש עשרה שעות',
        '17H': 'שבע עשרה שעות',
        '18H': 'שמונה עשרה שעות',
        '19H': 'תשע עשרה שעות',
        '20H': 'עשרים שעות',
        '21H': 'עשרים ואחת שעות',
        '22H': 'עשרים ושתיים שעות',
        '23H': 'עשרים ושלוש שעות',
        '24H': 'עשרים וארבע שעות',
    }
    if (doesContainHalf) {
        return hoursTranslations[hour] + ' וחצי'
    }
    return hoursTranslations[hour]
}

export const distance = (num1, num2) =>
    Math.sqrt((num1[0] - num2[0]) ** 2 + (num1[1] - num2[1]) ** 2)

export const navigateToSinglePage = (navigate, single) => {
    const urlTitles =
        urlEncode(single.site.region.title) +
        '/' +
        urlEncode(single.site.title) +
        '/' +
        urlEncode(single.title)
    navigate(`/single/${urlTitles}`, {state: {id: single._id}})
}

export const isExternalLink = (link) => link.indexOf('http') === 0
