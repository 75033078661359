import thunk from 'redux-thunk'
import logger from 'redux-logger'
import app from 'store/app/app.reducer'
import hero from 'store/hero/hero.reducer'
import header from 'store/header/header.reducer'
import singles from 'store/singles/singles.reducer'
import {createStore, applyMiddleware, combineReducers} from 'redux'

const rootReducer = combineReducers({
    app,
    singles,
    header,
    hero,
})

// Create the store
const store = createStore(rootReducer, applyMiddleware(thunk, logger))

export default store
