import {Colors} from 'appearance'
import {React, styled} from 'common'

const Amount = ({amount}) => {
    return <Container>{amount}</Container>
}

export default Amount

const Container = styled.div`
    font-size: 14px;
    font-weight: 600;
    align-self: center;
    border: ${Colors.PRIMARY} solid 2px;
    color: ${Colors.PRIMARY};
    line-height: 5px;
    border-radius: 8px;
    padding: 10px 7px;
    margin-left: 5px;
`
