import {
    useLocation,
    useDispatch,
    useCallback,
    useArticles,
} from 'services/hooks'
import {React} from 'common'
import {Page} from 'components/Page'
import {urlEncode} from 'services/utils/utils'
import {getArticles} from 'store/actions'
import ArticleDescription from 'components/Articles/Article'

const Article = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const articleName = location.pathname.split('/').pop()
    const articles = useArticles()
    const article = articles.find(
        (article) =>
            urlEncode(article.title) === articleName ||
            encodeURI(urlEncode(article.title)) === articleName //encodeURI in case user got here directly from url
    )

    const getScreenRequiredData = useCallback(() => {
        dispatch(getArticles())
    }, [dispatch])

    return (
        <Page
            path={window.location.pathname}
            getScreenRequiredData={getScreenRequiredData}
            title={article?.title}
            screenRequiredData={[articles]}>
            <ArticleDescription
                content={article?.content}
                date={article?.created}
                title={article?.title}
                preview={false}
            />
        </Page>
    )
}

export default Article
