import {React, styled} from 'common'
import {Colors, Fonts, Images} from 'appearance'
import StyledSelect from 'components/Home/Hero/Select'
import {RoundButton} from 'components/Control/Buttons/RoundButton'
import {
    useMemo,
    useCallback,
    useState,
    useSites,
    useRegions,
} from 'services/hooks'
import {allRegions, allSites, difficulties} from 'constants/home/search'

const createSelectOptions = (options) =>
    options?.map(({title, _id}) => ({
        text: title,
        href: _id,
    }))

const findOptionById = (options, id) => options.find((site) => site._id === id)

const filterById = (field, singleField) =>
    !field || singleField._id === field._id

const SinglesFinder = ({singles, setFilteredSingles, isMobile}) => {
    const regions = useRegions()
    const sites = useSites()

    const [region, setRegion] = useState(null)
    const [site, setSite] = useState(null)
    const [difficulty, setDifficulty] = useState(null)
    const [search, setSearch] = useState('')

    const regionsSelectOptions = useMemo(
        () => [allRegions, ...createSelectOptions(regions)],
        [regions]
    )

    const getFilteredSites = useCallback(() => {
        if (region) {
            return sites.filter((site) => site.region._id === region._id)
        }
        return sites
    }, [sites, region])

    const sitesSelectOptions = useMemo(() => {
        const filteredSites = getFilteredSites()
        return [allSites, ...createSelectOptions(filteredSites)]
    }, [getFilteredSites])

    const onChangeRegion = (id) => {
        setRegion(findOptionById(regions, id))
        setSite(null)
    }

    const onChangeSite = (id) => {
        setSite(findOptionById(sites, id))
    }

    const getFilteredSingles = () => {
        return singles.filter(
            (single) =>
                filterById(region, single.site.region) &&
                filterById(site, single.site) &&
                (!difficulty || single.technicalDifficulty === difficulty) &&
                (!search || single.title.includes(search))
        )
    }

    const doFilter = () => {
        console.log('do clean', region, site, search, difficulty)
        if (!region && !site && !search && !difficulty) {
            setFilteredSingles(null)
            return
        }

        setFilteredSingles(getFilteredSingles())
    }

    const onSearchKeyPress = (ev) => {
        if (ev.keyCode === 13 || ev.which === 13) {
            doFilter()
        }
    }

    return (
        <Container isMobile={isMobile}>
            <Search
                placeholder="חפש"
                enterButton="Search"
                size="large"
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={onSearchKeyPress}
                autocomplete
                type="search"
            />
            {regions && (
                <StyledSelect
                    title="כל האזורים"
                    items={regionsSelectOptions}
                    onChange={onChangeRegion}
                    value={region?.title}
                />
            )}
            {sites && (
                <StyledSelect
                    title="כל האתרים"
                    items={sitesSelectOptions}
                    onChange={onChangeSite}
                    value={site?.title}
                />
            )}
            <StyledSelect
                title="כל הרמות"
                items={difficulties}
                onChange={setDifficulty}
                value={difficulty}
            />
            <RoundButton onClick={doFilter}>סנן</RoundButton>
        </Container>
    )
}
export default SinglesFinder

const Container = styled.div`
    align-items: center;
    ${({isMobile}) => isMobile && `flex-direction: column;`}
    justify-content: stretch;
    align-content: space-around;
    display: flex;
    align-self: center;
`

const Search = styled.input`
    ::placeholder {
        color: ${Colors.APP_WHITE};
    }
    font-family: ${Fonts.primary};
    color: ${Colors.APP_WHITE};
    border-radius: 34px;
    border: none;
    padding: 16px;
    margin: 0px 4px 5px;
    flex-grow: 4;
    direction: rtl;
    font-size: 15px;
    text-align: right;
    outline: none;
    :hover {
        cursor: text;
    }
    background: ${Colors.PRIMARY} url(${Images.glow}) no-repeat;
    background-position: 8px 5px;

    ::after {
        background-image: url(${Images.glow});
    }
`
