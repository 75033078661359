import {v4 as uuidv4} from 'uuid'
import {React, styled} from 'common'
import {Colors, Icons} from 'appearance'
import {useSingles} from 'services/hooks'
import {Dropdown as AntdDropdown, Menu} from 'antd'
import headerLinksSort from 'services/utils/headerLinksSort'
import {getFeaturedSingle, getFeaturedSingleLinks} from 'services/utils/single'
import {isExternalLink} from 'services/utils/utils'

const Dropdown = ({title, items, arrow, width, withIcon, ...props}) => {
    const singles = useSingles()
    const icon = withIcon && Icons.subMenuExpand
    const featuredSingle = getFeaturedSingle(singles) || singles?.[0]
    const featuredSingleLinks = getFeaturedSingleLinks(featuredSingle)

    const menu = (
        <MenuContainer
            expandIcon={<></>}
            width={width}
            style={{
                width: width || 240,
            }}>
            {items?.map((item) => {
                const link = headerLinksSort(item, featuredSingleLinks)
                return (
                    <Menu.Item icon={icon} key={uuidv4()} title={item.title}>
                        <MainNavBarItem
                            style={{
                                padding: '0px 15px',
                            }}
                            onClick={() =>
                                window.open(
                                    link,
                                    isExternalLink(link) ? '_blank' : '_parent'
                                )
                            }>
                            {item.title}
                        </MainNavBarItem>
                    </Menu.Item>
                )
            })}
        </MenuContainer>
    )

    return (
        <AntdDropdown
            overlay={menu}
            trigger={['click']}
            placement="bottom"
            {...props}>
            <StyledLink className="ant-dropdown-link">
                {title}
                {arrow}
            </StyledLink>
        </AntdDropdown>
    )
}

export default Dropdown

const MenuContainer = styled(Menu)`
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    right: 27px;
    border: 1px solid #00000026;
    @media (pointer: none), (pointer: coarse) {
        position: fixed;
        margin-left: -120px;
        height: 350px;
        padding: 10px 10px 120px 0;
        border-bottom: inherit;
    }
    .ant-dropdown-menu-item {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        text-align: right;
        padding: 20px 10px;
        &:hover {
            color: ${Colors.PRIMARY};
        }
    }
`

const StyledLink = styled.a`
    color: ${Colors.SECONDARY};
    text-decoration-line: none;
    padding: 5px 20px;
    position: relative;
    line-height: 70px;
    &:hover {
        color: ${Colors.PRIMARY} !important;
    }
`

const MainNavBarItem = styled.div``
