import {Colors} from 'appearance'
import {React, styled} from 'common'
import {useState} from 'services/hooks'

const SlideShow = ({images}) => {
    const [slideIndex, setSlideIndex] = useState(images.length - 1)
    if (!images) {
        return
    }
    const prevImg = () =>
        slideIndex === images.length - 1 ? null : setSlideIndex(slideIndex + 1)
    const nextImg = () =>
        slideIndex === 0 ? null : setSlideIndex(slideIndex - 1)
    const dotClicked = (index) =>
        index === slideIndex ? null : setSlideIndex(index)
    const dots = Array.from({length: images.length}, (image, index) => index)

    return (
        <Container>
            <Img
                src={window.AWS_IMAGES_URL + images[slideIndex]}
                alt="can't show image :("
            />
            {images.length > 1 && (
                <>
                    <LeftBtn onClick={prevImg}>&#10095;</LeftBtn>
                    <RightBtn onClick={nextImg}>&#10094;</RightBtn>
                    <Dots>
                        {dots.map((dot, index) => (
                            <Dot
                                key={index}
                                selected={slideIndex === index}
                                name={'slides'}
                                onClick={() => {
                                    dotClicked(index)
                                }}
                            />
                        ))}
                    </Dots>
                </>
            )}
        </Container>
    )
}

export default SlideShow

const Container = styled.div`
    display: block;
    position: relative;
    margin: auto;
    @keyframes fade {
        from {
            opacity: 0.4;
        }
        to {
            opacity: 1;
        }
    }
`

const Img = styled.img`
    animation-name: fade;
    animation-duration: 1.5s;
    width: 100%;
    height: 388px;
`

const Btn = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 15%;
    height: 100%;
    padding: 16px;
    color: ${Colors.APP_WHITE};
    font-weight: 1000;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    background-repeat: repeat-x;
`

const RightBtn = styled(Btn)`
    border-radius: 0 3px 3px 0;
    &:hover {
        background-image: linear-gradient(
            to right,
            rgba(0, 0, 0, 0.0001) 0%,
            rgba(0, 0, 0, 0.5) 100%
        );
    }
`

const LeftBtn = styled(Btn)`
    left: 0;
    border-radius: 3px 0 0 3px;
    &:hover {
        background-image: linear-gradient(
            to left,
            rgba(0, 0, 0, 0.0001) 0%,
            rgba(0, 0, 0, 0.5) 100%
        );
    }
`

const Dot = styled.div`
    cursor: pointer;
    height: 12px;
    width: 12px;
    margin: 0 2px;
    border: 1px solid white;
    background-color: ${({selected}) =>
        selected ? Colors.APP_WHITE : 'transparent'};
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    &:active {
        background-color: ${Colors.APP_WHITE};
    }
    &:hover {
        background-color: ${Colors.APP_WHITE};
    }
`

const Dots = styled.div`
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
`
