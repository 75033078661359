import {
    EmailShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
} from 'react-share'
import React from 'react'
import {Icons} from 'appearance'

const ShareButtons = () => {
    const url = window.location.href
    return (
        <>
            <FacebookShareButton url={url}>
                {Icons.facebook}
            </FacebookShareButton>
            <TwitterShareButton url={url}>{Icons.twitter}</TwitterShareButton>
            <WhatsappShareButton url={url}>
                {Icons.whatsapp}
            </WhatsappShareButton>
            <LinkedinShareButton url={url}>{Icons.linkdin}</LinkedinShareButton>
            <PinterestShareButton url={url}>
                {Icons.pinterest}
            </PinterestShareButton>
            <EmailShareButton url={url}>{Icons.email}</EmailShareButton>
        </>
    )
}

export default ShareButtons
