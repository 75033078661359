import {React} from 'common'
import {useCallback} from 'react'
import {Page} from 'components/Page'
import {getSingles} from 'store/actions'
import SinglesResult from 'components/SearchResults/SinglesResult'
import {useLocation, useSingles, useDispatch} from 'services/hooks'

const SearchResults = () => {
    const dispatch = useDispatch()

    const getScreenRequiredData = useCallback(
        () => dispatch(getSingles()),
        [dispatch]
    )

    const location = useLocation()

    const search = decodeURIComponent(location.state.search)
        .substring(2)
        .split('+')
        .join(' ')
    const singles = useSingles()
    const filteredSingles = singles?.filter((single) =>
        single.title.includes(search, 0)
    )
    const title =
        filteredSingles?.length > 0
            ? 'תוצאות חיפוש עבור: ' + search
            : 'לא נמצאו תוצאות'

    return (
        <Page
            title={title}
            getScreenRequiredData={getScreenRequiredData}
            screenRequiredData={[singles]}>
            <SinglesResult singles={filteredSingles} />
        </Page>
    )
}

export default SearchResults
