import {Colors, Texts} from 'appearance'
import {React, styled} from 'common'
import {useState} from 'services/hooks'

const SingleMapWidget = ({text, image}) => {
    const [showText, setShowText] = useState(false)

    return (
        <Container>
            {showText && <Text>{text}</Text>}
            <Widget
                src={image}
                alt="start marker"
                onClick={() => setShowText(!showText)}
            />
        </Container>
    )
}

export default SingleMapWidget

const Container = styled.div`
    position: absolute;
`

const Widget = styled.img`
    cursor: pointer;
    align-self: center;
    position: relative;
    bottom: 40px;
    left: 17px;
`

const Text = styled(Texts.MarkerMapTitle)`
    background-color: ${Colors.APP_GREEN_LIGHT};
    position: absolute;
    bottom: 76px;
    left: -6px;
    white-space: nowrap;
    padding: 0 2px;
`
