import haversineDistance from 'haversine-distance'
import {urlEncode} from './utils'

export const findSingleByName = (singleName, singles) =>
    singles?.find((single) => urlEncode(single.title) === singleName)

export const getSingleTrack = (single) =>
    single?.parsedFile?.features?.find(
        (feature) => feature?.geometry?.type === 'LineString'
    )

export const getFeaturedSingle = (singles) =>
    singles?.find((single) => single.featured === true)

export const getFeaturedSingleLinks = (featuredSingle) =>
    urlEncode(featuredSingle?.site?.region?.title) +
    '/' +
    urlEncode(featuredSingle?.site?.title) +
    '/' +
    urlEncode(featuredSingle?.title)

export const getSingleCoordinates = (single) => {
    const track = getSingleTrack(single)

    let trackCoordinates = track?.geometry?.coordinates
    if (!track) {
        const tracksSegments = single?.parsedFile?.features?.find(
            (feature) => feature?.geometry?.type === 'GeometryCollection'
        )
        trackCoordinates = tracksSegments?.geometry?.geometries?.flatMap(
            (segment) => segment.coordinates
        )
    }
    return trackCoordinates?.map((coordinate) => [
        +coordinate[0]?.toFixed(4),
        +coordinate[1]?.toFixed(4),
        coordinate[2],
    ])
}

const getSingleWaypoints = (single) => {
    const roadmarks = single?.parsedFile?.features?.filter(
        (feature) => feature?.geometry?.type === 'Point'
    )

    return roadmarks?.map((mark) => {
        return {
            coordinates: {
                lat: mark.geometry.coordinates[1],
                lng: mark.geometry.coordinates[0],
            },
            description: mark.properties.name,
            time: mark.properties.time,
        }
    })
}

const calculateDistanceBetweenTrackCoordinates = (c1, c2) =>
    haversineDistance([c1[1], c1[0]], [c2[1], c2[0]])

export const getChartData = (decimatedCoordinates) => {
    let distance = 0
    const chartDataLabels = []
    const chartDataHeight = []
    decimatedCoordinates?.forEach((coordinate, i) => {
        const nextCoordinate = decimatedCoordinates[i + 1]

        if (!nextCoordinate) {
            chartDataLabels.push('סיום')
        } else {
            distance += calculateDistanceBetweenTrackCoordinates(
                coordinate,
                nextCoordinate
            )

            if (i === 0) {
                chartDataLabels.push('התחלה')
            } else {
                chartDataLabels.push('מרחק: ' + distance.toFixed(2))
            }
        }

        chartDataHeight.push(coordinate[2])
    })

    return {
        y: {label: 'גובה', data: chartDataHeight},
        x: chartDataLabels,
    }
}

const getDecimatedCoordinates = (coordinates) =>
    coordinates?.filter((coordinate, index) => {
        if (index % 10 === 0) {
            coordinate.push(index)
            return true
        }
        return false
    })

export const getSingleData = (single) => {
    const coordinates = getSingleCoordinates(single)
    const waypoints = getSingleWaypoints(single)
    const decimatedCoordinates = getDecimatedCoordinates(coordinates)
    const chartData = getChartData(decimatedCoordinates)
    return {coordinates, waypoints, decimatedCoordinates, chartData}
}
