import 'moment/locale/he'
import moment from 'moment'
import {Icons} from 'appearance'
import Moment from 'react-moment'
import {React, styled} from 'common'

const Date = ({date}) => {
    moment.locale('he')
    return (
        <Container>
            {Icons.watch}
            <Moment locale="he" format="  MMM DD, YYYY ">
                {date}
            </Moment>
        </Container>
    )
}
export default Date

const Container = styled.div``
