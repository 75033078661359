import {useAuth, useState, useUserName, useWindowSize} from 'services/hooks'
import {React, styled} from 'common'
import {Colors, Icons} from 'appearance'
import ClearSearchBar from 'components/Page/Header/SearchBar'
import HeaderControlls from 'components/Page/Header/HeaderControlls'

const HeaderControllers = ({toggleDrawer, menuItems}) => {
    const isAuthenticated = useAuth()
    const username = useUserName()

    const [showSearch, setShowSearch] = useState(false)
    const windowSize = useWindowSize()

    const onSearchClick = () => setShowSearch(true)

    const onSearchBlur = () => setShowSearch(false)

    const showAllControllers = windowSize.width > 990
    // if (menuItems?.length === 0) {
    //     return null
    // }

    if (showAllControllers) {
        return (
            <Controllers hide={!showAllControllers}>
                {/* {Icons.login} */}
                {isAuthenticated && username && <User>{username}</User>}
                <ClearSearchBar
                    onClicked={onSearchClick}
                    onBlured={onSearchBlur}
                    showSearch={showSearch}
                />

                <HeaderControlls show={showAllControllers && !showSearch}>
                    {Icons.home}
                </HeaderControlls>
            </Controllers>
        )
    }

    return <MobileBtn onClick={toggleDrawer}> &#9776;</MobileBtn>
}

export default HeaderControllers

const Controllers = styled.div`
    ${({hide}) => !hide && 'flex:1'};
    font-size: 16px;
    align-items: center;
    justify-content: space-around;
    display: flex;
`

const MobileBtn = styled.button`
    &:hover {
        background-color: ${Colors.DRAWER.BUTTON_ACTIVE};
        cursor: pointer;
    }
    font-size: 40px;
    border: none;
    background-color: transparent;
    color: ${Colors.PRIMARY};
`

const User = styled.div`
    background-color: transparent;
    color: transparent;
    border-color: transparent;
    &:hover {
        cursor: pointer;
    }
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 18px;
    color: ${Colors.PRIMARY};
    padding-right: 80px;
    @media (max-width: 1200px) {
        padding-right: 80px;
        margin-left: 16px;
    }
`
