const defaultState = {
    singles: null,
}

const SinglesReducer = (state = defaultState, action) => {
    const actionObject = {
        SET_SINGLES: () => {
            return {...state, singles: action.singles}
        },
    }

    const actionFunc = actionObject[action?.type]
    if (actionFunc) {
        return actionFunc(action)
    }

    return state
}

export default SinglesReducer
