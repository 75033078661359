const he = {
    home: {
        hero_catch_phrase: 'מסנגלים - מצא את הסינגל שלך!',
    },
    general: {
        invalid_password: 'סיסמה חייבת להיות בת 8 תוים לפחות',
        invalid_verify_password: 'הסיסמה לא תואמת את שדה הסיסמה הראשון',
    },
    difficulty: {
        Easy: 'קל',
        Normal: 'בינוני',
        Hard: 'קשה',
    },
}

export default he
