import {React, styled} from 'common'
import Flatlist from 'flatlist-react'
import NoSingle from 'components/Page/Content/NoSingle'
import SingleCard from 'components/Control/Display/SingleCard'
import Section from 'components/Page/Section'

const SinglesList = ({singles, backgroundColor, ...p}) => {
    return (
        <Container backgroundColor={backgroundColor} padded {...p}>
            <Flatlist
                list={singles}
                renderItem={(single, idx) => (
                    <SingleCard single={single} key={idx} />
                )}
                renderWhenEmpty={() => <NoSingle />}
            />
        </Container>
    )
}

export default SinglesList

const Container = styled(Section)`
    flex-flow: row wrap;
    gap: 32px;
    justify-content: center;
`
