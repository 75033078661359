import {React, styled} from 'common'
import Amount from 'components/Page/Header/Amount'

const SiteOnDropdown = ({name, amount, onClick}) => {
    return (
        <Container onClick={onClick}>
            <SingleName>{name}</SingleName>
            <Amount amount={amount} />
        </Container>
    )
}

export default SiteOnDropdown

const Container = styled.div`
    display: flex;
    flex-flow: row;
    padding: 5px;
    .ant-dropdown-menu-title-content {
        display: flex;
        flex-direction: row;
    }
`

const SingleName = styled.div`
    align-self: center;
    flex: 1;
    margin-right: 5px;
`
