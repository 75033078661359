export const getTrackData = (single) => {
    return single?.parsedFile?.features?.find(
        (feature) => feature?.geometry?.type === 'LineString'
    )
}

export const getStartMarker = (single) => {
    const trackData = getTrackData(single)
    const startCoordinates = trackData?.geometry?.coordinates[0]
    const {site, technicalDifficulty: difficulty} = single

    return {
        ...single,
        coordinates: startCoordinates,
        difficulty,
        siteTitle: site.title,
    }
}

export const mapOptions = (maps) => ({
    mapTypeControl: true,
    streetViewControl: true,
    mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_LEFT,
    },
    fullscreenControl: false,
})

const openFullscreen = async (element) => await element.requestFullscreen()

const closeFullscreen = async () => await document.exitFullscreen()

export const toggleMapFullScreen = async (mapRef) => {
    try {
        if (document.fullscreenElement) {
            await closeFullscreen()
        } else {
            await openFullscreen(mapRef.current)
        }
    } catch (error) {
        console.log('error: ', error)
    }
}

export const clearMarkers = (markers) => {
    for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(null)
    }
}

export const setCursorHoverOnTrackListener = (
    maps,
    track,
    setCursorPosition
) => {
    return maps.event.addListener(track, 'mouseover', (event) => {
        const latLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        }

        setCursorPosition(latLng)
    })
}

export const getTrackCenter = (coordinates) => {
    return {
        lat: (coordinates[0][1] + coordinates[coordinates.length - 1][1]) / 2,
        lng: (coordinates[0][0] + coordinates[coordinates.length - 1][0]) / 2,
    }
}

export const getTrackStart = (coordinates) => {
    return {lat: coordinates[0][1], lng: coordinates[0][0]}
}

export const getTrackEnd = (coordinates) => {
    return {
        lat: coordinates[coordinates.length - 1][1],
        lng: coordinates[coordinates.length - 1][0],
    }
}

export const getMainTrackCoordinates = (coordinates) => {
    const trackStart = getTrackStart(coordinates)
    const trackEnd = getTrackEnd(coordinates)
    const isRoundTrack = trackStart === trackEnd
    return {
        trackStart,
        trackCenter: getTrackCenter(coordinates),
        trackEnd,
        isRoundTrack,
    }
}

export const formatCoordinates = (coordinates) => {
    return coordinates.map((coordinate) => ({
        lat: coordinate[1],
        lng: coordinate[0],
    }))
}

export const setMapBoundsFromSingleTrack = (track, map, maps) => {
    const bounds = new maps.LatLngBounds()
    const path = track.getPath()
    path[Object.keys(path)[0]]?.map((latLng) => bounds.extend(latLng))
    map.fitBounds(bounds)
}

export const addTrack = (coords, map, maps) => {
    const coordinates = formatCoordinates(coords)
    const track = new maps.Polyline({
        path: coordinates,
        geodesic: true,
        strokeColor: 'blue',
        strokeOpacity: 0.8,
        strokeWeight: 5,
    })
    track.setMap(map)
    return track
}

export const setCursorHoverMarkerOnMap = (
    cursorPosition,
    icon,
    cursorHoverMarker,
    trackState,
    mapObject
) => {
    if (!cursorHoverMarker || !mapObject) {
        return
    }

    const {map, maps} = mapObject

    if (cursorPosition) {
        const mouseOnTrack = new maps.Marker({
            position: cursorPosition,
            map,
            icon,
        })
        cursorHoverMarker.current?.setMap(null)
        mouseOnTrack.setMap(map)
        cursorHoverMarker.current = mouseOnTrack
        maps.event.addListener(trackState, 'mouseout', () => {
            mouseOnTrack.setMap(null)
        })
    } else {
        cursorHoverMarker.current?.setMap(null)
    }
}

const getSiteCenter = (topLeft, bottomRight) => ({
    lat: (topLeft[1] + bottomRight[1]) / 2,
    lng: (topLeft[0] + bottomRight[0]) / 2,
})

export const getSiteBounds = (singleStartMarkers) => {
    if (!singleStartMarkers) {
        return
    }

    const lats = singleStartMarkers.map(
        (startMarker) => startMarker.coordinates[1]
    )

    const lngs = singleStartMarkers.map(
        (startMarker) => startMarker.coordinates[0]
    )

    const topLeft = [Math.min(...lngs), Math.max(...lats)]
    const bottomRight = [Math.max(...lngs), Math.min(...lats)]
    const siteCenter = getSiteCenter(topLeft, bottomRight)

    return {
        topLeft,
        bottomRight,
        siteCenter,
    }
}
