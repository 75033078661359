import {React, styled} from 'common'
import Page from 'components/Page/Page'
import {Colors, Texts} from 'appearance'
import Row from 'components/Control/Display/Row'

const About = () => {
    return (
        <Page title="אודות">
            <Content>
                <Row>ברוכים הבאים לאתר מסנגלים !</Row>
                <Row>
                    האתר הוקם למען קהילת רוכבי אופני השטח, ומציע מגוון רחב של
                    סינגלים (מסלולי רכיבה צרים, המתאימים ברוחבם לרוכב אחד).
                </Row>
                <Row>
                    מטרת האתר לאפשר לך הרוכב/ת לחפש ולבחור את הסינגל המתאים לך,
                    בדרך הקלה והמהירה – בהתאם למיקום גאוגרפי, בהתאם לרשימת אתרי
                    רכיבה ובהתאם לרמת הקושי של הסינגל.
                </Row>
                <Row>
                    האתר מציג מידע איכותי על כל אחד מהסינגלים, הכולל תיאור
                    מילולי (אופי הסינגל, אורכו, רמת קושי, זמן רכיבה ועוד), מפה,
                    גרף גבהים ותמונות מהסינגל, המאפשרים לך לבחור סינגל לרכיבה.
                </Row>
                <Row>
                    הסינגלים באתר מסומנים בשלושה צבעים (ירוק, צהוב ואדום), בהתאם
                    לרמת הקושי. סינגלים בדרגת קושי קלה – צבע ירוק, סינגלים בדרגת
                    קושי בינונית – צבע צהוב, וסינגלים בדרגת קושי גבוהה – צבע
                    אדום.
                </Row>
                <Row>
                    האתר מיועד לכל סוגי הרוכבים. רוכבים ותיקים יוכלו להעשיר את
                    היצע הסינגלים המוכר להם, ורוכבים חדשים יוכלו לאתר את המקומות
                    המתאימים להם, בהתאם לדרגת הקושי.
                </Row>
                <Row>
                    האתר מותאם לטלפונים חכמים, מכשירי טאבלט ומחשבים נייחים.
                </Row>
                <Row>
                    בנוסף ניתן למצוא באתר סרטוני רכיבה של הרוכבים הטובים בעולם,
                    כתבות, אפליקציות, המלצות ועוד …
                </Row>
                <Row>
                    האתר הוקם על ידי שני רוכבים – תמיר שרצר וגל קפלן, שמזה
                    כעשור, רוכבים ונהנים ממגוון סינגלים מרהיבים, בכל רחבי הארץ.
                </Row>
                <Row>
                    אנו מקווים שתיהנו מחוויית הגלישה באתר ומאחלים לכם רכיבה
                    נעימה ובטוחה.
                </Row>
                <Row>
                    לעיון בתנאי השימוש של האתר נא ללחוץ{' '}
                    <Terms
                        rel="noopener noreferrer"
                        href="terms"
                        title="תנאי שימוש">
                        כאן!
                    </Terms>
                </Row>
            </Content>
        </Page>
    )
}

export default About

const Content = styled(Texts.ContentDescription)`
    margin: 40px auto auto auto;
    max-width: 800px;
    width: 100%;
    padding-bottom: 50px;
`

const Terms = styled.a`
    color: ${Colors.PRIMARY};
`
