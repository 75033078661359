import './App.css'
import 'app_globals.js'
import 'antd/dist/antd.css'
import {React} from 'common'
import store from 'store/Store'
import {Provider} from 'react-redux'
import {initFacebookSdk} from 'services/init-facebook-sdk'
import NavigationRouter from './navigation/NavigationRouter'
import {initGoogleAnalytics} from 'services/init-google-analytics'

initGoogleAnalytics()
initFacebookSdk().then(App)

function App() {
    return (
        <Provider store={store}>
            <NavigationRouter />
        </Provider>
    )
}

export default App
