const defaultState = {
    search: null,
    menuItems: [],
}

const HeaderReducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_SEARCH':
            return {...state, search: action.search}
        case 'SET_MENU_ITEMS':
            return {...state, menuItems: action.menuItems}
        default:
            return state
    }
}

export default HeaderReducer
