import {i18n, React, styled} from 'common'
import {Colors, Fonts, Images} from 'appearance'
import Section from 'components/Page/Section'
import SinglesFinder from './Hero/SinglesFinder'
import {useWindowSize} from 'services/hooks'

const Hero = ({singles, setFilteredSingles}) => {
    const width = useWindowSize().width
    let isMobile = width <= 990
    return (
        <MainImage isMobile={isMobile}>
            <Catchphrase isMobile={isMobile}>
                {i18n.t('home.hero_catch_phrase')}
            </Catchphrase>
            <SinglesFinder
                isMobile={isMobile}
                setFilteredSingles={setFilteredSingles}
                singles={singles}
            />
        </MainImage>
    )
}
export default Hero

const MainImage = styled(Section)`
    height: 600px;
    background-image: url(${Images.main});
    background-repeat: no-repeat;
    background-color: ${Colors.APP_GRAY_LIGHT};
    background-origin: border-box;
    background-size: cover;
    justify-content: ${({isMobile}) =>
        isMobile ? `space-evenly` : `flex-end`};
    ${({isMobile}) => !isMobile && `padding: 40px;`}
    ${({isMobile}) => isMobile && `align-items: center;`}
`
const Catchphrase = styled.h1`
    font-family: ${Fonts.primary};
    font-size: 2.25em;
    text-align: center;
    ${({isMobile}) => isMobile && `width: fit-content;`}
    color: ${Colors.APP_WHITE};
`
