import {Images} from 'appearance'
import {React, styled} from 'common'
import {useWindowSize, useEffect} from 'services/hooks'

const Loading = ({isLoading, setShowContent}) => {
    let imageSize = useWindowSize()
    if (imageSize.width > 492 && imageSize.height > 227) {
        imageSize = {width: 492, height: 227}
    }
    useEffect(() => {
        if (isLoading === false) {
            setTimeout(() => {
                setShowContent(true)
            }, 300)
        }
    }, [isLoading, setShowContent])
    return (
        <Container isLoading={isLoading}>
            <Logo
                src={Images.bigLogo}
                width={imageSize.width * 0.9}
                height={(imageSize.width / 2.1674) * 0.9}
            />
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({isLoading}) => !isLoading && 'transition: fade 0.3s ease 0s;'}
    @keyframes fade {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
`

const Logo = styled.img`
    animation-name: fade;
    animation-duration: 3s;
`

export default Loading
