import api from 'services/api'

export const setSingles = (singles) => {
    return {
        type: 'SET_SINGLES',
        singles,
    }
}

export const updateSingle = async (singleId = '', body) => {
    try {
        const res = await api.saveSingle(singleId, body)
        console.log('updateSingle RES = ', res)
    } catch (error) {
        console.error('getSingles error: ', error)
    }
}

export const getSingles = () => {
    return async (dispatch, getState) => {
        try {
            const res = await api.getSingles()
            dispatch(setSingles(res))
        } catch (error) {
            console.error('getSingles error: ', error)
        }
    }
}
