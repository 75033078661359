const links = (featuredSingleLink) => [
    {
        title: 'חדש במסנגלים',
        link: '/new/',
    },
    {title: 'סינגל החודש', link: '/single/' + featuredSingleLink},
    {
        title: 'תחזית מזג האויר',
        link: 'https://www.israelweather.co.il/forecast/week.html',
    },
    {
        title: 'GPX Viewer',
        link: 'https://vecturagames.com/',
    },
    {
        title: 'Endmondo',
        link: 'https://www.mapmyfitness.com/',
    },
    {
        title: 'Strava',
        link: 'https://www.strava.com/',
    },
    {
        title: 'My Tracks',
        link: 'https://play.google.com/store/apps/details?id=com.zihua.android.mytracks',
    },
]

export default links
