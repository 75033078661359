import {Colors} from 'appearance'
import {React, styled, i18n} from 'common'

const Difficulty = ({difficulty}) => {
    return (
        <Container
            color={Colors.DIFFICULTY[difficulty].text}
            difficultyColor={Colors.DIFFICULTY[difficulty].background}>
            {i18n.t('difficulty.' + difficulty)}
        </Container>
    )
}

export default Difficulty

const Container = styled.div`
    color: ${(p) => p.difficultyColor};
    ::after {
        width: 40px;
        height: 5px;
        content: '';
        background-color: ${(p) => p.color};
        margin: 11px auto 40px;
        display: block;
    }
`
