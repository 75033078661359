import axios from 'axios'
export const BASE_URL = window.API_URL || 'http://localhost:3300/' // 'http://localhost:3510/api' //

const HEADERS = {
    'Content-Type': 'application/json',
}

console.log('api base url', BASE_URL)
class Api {
    constructor() {
        this.baseUrl = BASE_URL
    }

    async request(
        url,
        method,
        params,
        data,
        headers = {},
        onSuccess,
        rememberMe = localStorage.getItem('@rememberMe')
    ) {
        try {
            const response = await axios.request({
                headers: {...HEADERS, ...headers},
                withCredentials: true,
                baseURL: this.baseUrl,
                method,
                url,
                data,
                params,
            })

            console.log('axios response', {response})
            onSuccess && onSuccess(response?.data)
            return response?.data
        } catch (error) {
            console.error('axios error', {error})
            // onError && (await onError(error))
            if (rememberMe === 'true') {
                return await this.reLoginWithRefreshToken(error)
            }
        }
    }

    async addUser(email, password, name) {
        return this.request('users/signup', 'POST', null, {
            email,
            password,
            name,
        })
    }

    async getCurrentUser() {
        return this.request(`auth/getCurrentUser`, 'GET')
    }

    async getItem(id, root) {
        return this.request(`${root}/${id}`, 'GET')
    }

    async getRegions() {
        return this.request(`regions`, 'GET')
    }

    async getRegion(id) {
        return this.request(`regions/${id}`, 'GET')
    }

    async getSites(regionId) {
        const regionStr = regionId ? `?region=${regionId}` : ''
        return this.request(`sites${regionStr}`, 'GET')
    }

    async getSite(id) {
        return this.request(`sites/${id}`, 'GET')
    }

    async getSiteByTitle(title) {
        return this.request(`sites?title=${title}`, 'GET')
    }

    async getSingle(id) {
        return this.request(`singles/${id}`, 'GET')
    }

    async getFeaturedSingle(id) {
        return this.request(`singles/featuredSingle`, 'GET')
    }

    async getSingles(siteId) {
        const siteStr = siteId ? `?site=${siteId}` : ''
        return this.request(`singles${siteStr}`, 'GET')
    }

    async getMenuItem(id) {
        return this.request(`menuitems/${id}`, 'GET')
    }

    async getMenuItems() {
        return this.request(`menuitems`, 'GET')
    }

    async updateMenuItem(menuItemId, body) {
        return this.request(`menuitems/${menuItemId}`, 'POST', null, body)
    }

    async updateVideo(videoId, body) {
        return this.request(`videos/${videoId}`, 'POST', null, body)
    }

    async getVideos() {
        return this.request(`videos`, 'GET')
    }

    async getArticles() {
        return this.request(`articles`, 'GET')
    }

    async getArticle(id) {
        return this.request(`articles/${id}`, 'GET')
    }

    async addArticle(title, author, content) {
        return this.request(`articles`, 'POST', null, {title, author, content})
    }

    async saveSingle(singleId, body) {
        return this.request(`singles/${singleId}`, 'POST', null, body)
    }

    async updateArticle(title, author, content, id) {
        return this.request(`articles/${id}`, 'POST', null, {
            title,
            author,
            content,
        })
    }

    async login(email, password) {
        return this.request(`auth/login`, 'POST', null, {email, password})
    }

    async logout() {
        return this.request('auth/logout', 'POST')
    }

    async uploadFile(formData, bucket, folder) {
        return this.request('/upload', 'POST', {bucket, folder}, formData, {
            'Content-Type': 'multipart/form-data',
            ...formData.getHeaders(),
        })
    }

    async forgotPassword(email) {
        return this.request('auth/forgotPassword', 'POST', null, {email})
    }

    async refreshToken() {
        return this.request('auth/refreshToken', 'GET')
    }

    async test() {
        return this.request('auth/refreshToken', 'GET')
    }

    async reLoginWithRefreshToken(err) {
        const originalReq = err.config
        console.log('message: ', originalReq)

        if (
            err.response?.status === 401 &&
            originalReq.url === 'auth/refreshToken'
        ) {
            return
        }

        originalReq._retry = true
        console.log('message: ', 'mid')
        console.log('class: ', this)

        try {
            await this.refreshToken()
            return await this.request(
                originalReq.url,
                originalReq.method,
                originalReq.params,
                originalReq.data,
                originalReq.headers,
                null,
                null
            )
        } catch (err) {
            console.error('refresh error: ', err)
            // window.location.href = '/login'
        }
    }
}
const api = new Api()
export default api
