import api from 'services/api'

export const setRegionId = (regionId) => {
    return {
        type: 'SET_REGION_ID',
        regionId,
    }
}

export const setSiteId = (siteId) => {
    return {
        type: 'SET_SITE_ID',
        siteId,
    }
}
export const setRegions = (regions) => {
    return {
        type: 'SET_REGIONS',
        regions,
    }
}

export const setSites = (sites) => {
    return {
        type: 'SET_SITES',
        sites,
    }
}

export const setDifficulty = (difficulty) => {
    return {
        type: 'SET_DIFFICULTY',
        difficulty,
    }
}

export const setFilter = (regionId, siteId, difficulty, search) => {
    return {
        type: 'SET_FILTER',
        regionId,
        siteId,
        difficulty,
        search,
    }
}

export const getRegions = () => {
    return async (dispatch, getState) => {
        try {
            const res = await api.getRegions()
            dispatch(setRegions(res))
        } catch (error) {
            console.error('getSingles error: ', error)
        }
    }
}

export const getSites = () => {
    return async (dispatch, getState) => {
        try {
            const res = await api.getSites()
            dispatch(setSites(res))
        } catch (error) {
            console.error('getSingles error: ', error)
        }
    }
}
