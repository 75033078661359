import api from 'services/api'

export const setAuth = (auth) => {
    return {
        type: 'SET_AUTH',
        auth,
    }
}

export const setUserName = (userName) => {
    return {
        type: 'SET_USER_NAME',
        userName,
    }
}

export const login = (email, password) => {
    return async (dispatch, getState) => {
        try {
            const res = await api.login(email, password)
            dispatch(setAuth(true))
            dispatch(setUserName(res?.name))
        } catch (error) {
            console.error('getArticle error: ', error)
        }
    }
}

export const setCancelPadding = (hide) => {
    return {
        type: 'SET_CANCEL_PADDING',
        hide,
    }
}

export const setArticles = (articles) => {
    return {
        type: 'SET_ARTICLES',
        articles,
    }
}

export const setShowFeaturedSingle = (showFeaturedSingle) => {
    return {
        type: 'SET_SHOW_FEATURED_SINGLE',
        showFeaturedSingle,
    }
}

export const updateVideo = (videoId, body) => {
    return async (dispatch, getState) => {
        try {
            const res = await api.updateVideo(videoId, body)
            console.log('RES = ', res)
        } catch (error) {
            console.error('update video error: ', error)
        }
    }
}

export const updateArticle = (articleId, body) => {
    return async (dispatch, getState) => {
        try {
            const res = await api.updateSingle(articleId, body)
            console.log('RES = ', res)
        } catch (error) {
            console.error('update article error: ', error)
        }
    }
}

export const setVideos = (videos) => {
    return {
        type: 'SET_VIDEOS',
        videos,
    }
}

export const getVideos = () => {
    return async (dispatch, getState) => {
        try {
            const res = await api.getVideos()
            dispatch(setVideos(res))
            return res
        } catch (error) {
            console.error('getVideos error: ', error)
            return
        }
    }
}

export const getArticles = () => {
    return async (dispatch, getState) => {
        try {
            const res = await api.getArticles()
            dispatch(setArticles(res))
            return res
        } catch (error) {
            console.error('getArticles error: ', error)
            return
        }
    }
}
