import {React, styled} from 'common'
import GoogleMapReact from 'google-map-react'
import Section from 'components/Page/Section'
import {useState, useRef, useMemo} from 'services/hooks'
import Controlls from 'components/Control/Display/Map/Controlls'
import InfoWindow from 'components/Control/Display/Map/InfoWindow'
import {mapOptions, getStartMarker, getSiteBounds} from 'services/utils/maps'
import FullscreenButton from 'components/Control/Display/Map/FullscreenButton'
import SingleStartMarker from 'components/Control/Display/Map/Site/SingleStartMarker'

const SiteMap = ({singles, displayedSingles}) => {
    const [selectedMarker, setSelectedMarker] = useState(null)
    const [mapObject, setMapObject] = useState(null)

    const mapRef = useRef()

    const displayedSinglesStartMarkers = useMemo(
        () => displayedSingles?.map(getStartMarker),
        [displayedSingles]
    )

    const singleStartMarkers = useMemo(
        () => singles?.map(getStartMarker),
        [singles]
    )

    const siteBounds = useMemo(
        () => getSiteBounds(singleStartMarkers),
        [singleStartMarkers]
    )

    const setMapBounds = (map, maps) => {
        const {topLeft, bottomRight} = siteBounds
        const bounds = new maps.LatLngBounds()
        bounds.extend(new maps.LatLng(topLeft[1], topLeft[0]))
        bounds.extend(new maps.LatLng(bottomRight[1], bottomRight[0]))
        map.fitBounds(bounds)
    }
    const {siteCenter} = siteBounds
    return (
        <Container>
            <InnerContainer ref={mapRef}>
                <FullscreenButton mapRef={mapRef} />
                <InfoWindow marker={selectedMarker} />
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: window.GOOGLE_MAPS_KEY,
                    }}
                    defaultCenter={siteCenter}
                    defaultZoom={14}
                    allowfullscreen
                    requestFullscreen
                    fullscreenControl
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({map, maps}) => {
                        setMapObject({map, maps})
                        setMapBounds(map, maps)
                    }}
                    options={mapOptions}
                    onClick={() => setSelectedMarker(null)}>
                    {displayedSinglesStartMarkers?.map((marker, i) => {
                        const [lng, lat] = marker.coordinates
                        return (
                            <SingleStartMarker
                                key={`marker_${i}`}
                                lat={lat}
                                lng={lng}
                                marker={marker}
                                onClick={() => setSelectedMarker(marker)}
                            />
                        )
                    })}
                </GoogleMapReact>
                <Controlls mapObject={mapObject} mapCenter={siteCenter} />
            </InnerContainer>
        </Container>
    )
}

export default SiteMap

const Container = styled(Section)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const InnerContainer = styled.div`
    width: 100%;
    height: 500px;
    position: relative;
`
