import {React, styled} from 'common'
import {Colors, Icons} from 'appearance'
import {useNavigate} from 'services/hooks'

const SearchBar = ({showSearch, onClicked, onBlured}) => {
    const border = showSearch ? 'solid 1px #f5761f' : '0'
    const navigate = useNavigate()
    const params = new URLSearchParams()
    const onTypeSearch = (ev) => {
        if (ev.keyCode === 13 || ev.which === 13) {
            params.append('q', ev.target.value)
            navigate('/search', {state: {search: params.toString()}})
        }
    }
    return (
        <SearchContainer
            flex={showSearch}
            onBlur={onBlured}
            onClick={onClicked}
            border={border}>
            {Icons.search}
            {showSearch && (
                <SearchInput
                    placeholder="חפש..."
                    enterButton="Search"
                    size="large"
                    onKeyPress={onTypeSearch}
                    autocomplete
                    type="text"
                    autoFocus
                />
            )}
        </SearchContainer>
    )
}

export default SearchBar

const SearchInput = styled.input`
    width: 100%;
    padding: 20px 0;
    outline: 0;
    border: 0;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: inline;
    appearance: none;
    background-color: ${Colors.APP_WHITE};
    -webkit-rtl-ordering: logical;
    cursor: text;
    direction: rtl;
`

const SearchContainer = styled.div`
    display: flex;
    ${({flex}) => flex && 'flex:1'};
    border: ${(props) => props.border};
    border-radius: 35px;
    &:hover {
        cursor: pointer;
    }
    overflow: hidden;
    margin-left: 50px;
    .anticon-search {
        font-size: 30px;
        position: relative;
        color: ${Colors.PRIMARY};
        margin: auto;
    }
`
