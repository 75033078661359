import {
    EmailIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    FacebookIcon,
    PinterestIcon,
} from 'react-share'
import {
    AimOutlined,
    MenuOutlined,
    PlusOutlined,
    InboxOutlined,
    UploadOutlined,
    SearchOutlined,
    ExpandOutlined,
    LoadingOutlined,
    DownloadOutlined,
    ShareAltOutlined,
    CompressOutlined,
    CaretDownOutlined,
    CaretLeftOutlined,
    ClockCircleOutlined,
} from '@ant-design/icons'
import LoginIcon from 'components/Page/Header/Login'
import HomeIcon from 'components/Page/Header/HomeIcon'

const icons = {
    home: <HomeIcon />,
    login: <LoginIcon />,
    add: <PlusOutlined />,
    menu: <MenuOutlined />,
    inbox: <InboxOutlined />,
    location: <AimOutlined />,
    upload: <UploadOutlined />,
    search: <SearchOutlined />,
    expand: <ExpandOutlined />,
    loading: <LoadingOutlined />,
    compress: <CompressOutlined />,
    download: <DownloadOutlined />,
    watch: <ClockCircleOutlined />,
    menuExpand: <CaretDownOutlined />,
    subMenuExpand: <CaretLeftOutlined />,
    share: <ShareAltOutlined size={50} url={document.URL} />,
    email: <EmailIcon size={50} borderRadius={20} url={document.URL} />,
    twitter: <TwitterIcon size={50} borderRadius={20} url={document.URL} />,
    linkdin: <LinkedinIcon size={50} borderRadius={20} url={document.URL} />,
    facebook: <FacebookIcon size={50} borderRadius={20} url={document.URL} />,
    whatsapp: <WhatsappIcon size={50} borderRadius={20} url={document.URL} />,
    pinterest: <PinterestIcon size={50} borderRadius={20} url={document.URL} />,
}

export default icons
