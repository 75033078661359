import {Colors} from 'appearance'
import Moment from 'react-moment'
import {React, styled} from 'common'
import Texts from 'appearance/texts'
import {useIsMobile} from 'services/hooks'
import Section from 'components/Page/Section'
import SingleCard from 'components/Control/Display/SingleCard'

const FeaturedSingle = ({single}) => {
    const isMobile = useIsMobile()
    if (!single) {
        return null
    }
    return (
        <Container
            backgroundColor={Colors.APP_GREY_SECTION}
            padded
            isMobile={isMobile}>
            <Card single={single} />
            <SingleDesc>
                <Title>{single.title}</Title>
                <Date format="DD-MM-YYYY">{single.created}</Date>
                <Desc>{single.description}</Desc>
            </SingleDesc>
        </Container>
    )
}

export default FeaturedSingle

const Container = styled(Section)`
    flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row-reverse')};
    ${({isMobile}) => isMobile && 'align-items: center'}
`

const SingleDesc = styled.div`
    flex-direction: column;
    flex: 1;
    margin-top: 80px;
`

const Title = styled(Texts.ContentTitle)`
    font-size: 30px;
`

const Date = styled(Moment)`
    color: ${Colors.APP_DATE};
`

const Desc = styled(Texts.ContentDescription)`
    margin-top: 10px;
    padding-bottom: 27px;
    margin-left: 10px;
`

const Card = styled(SingleCard)``
