import {Menu} from 'antd'
import {React} from 'common'
import {v4 as uuidv4} from 'uuid'
import {getSingles} from 'store/actions'
import {urlEncode} from 'services/utils/utils'
import {useSingles, useCallback, useDispatch, useNavigate} from 'services/hooks'
import Site from 'components/Page/Header/Dropdown/Menu/SiteOnDropdown'

const MenuItemContainer = ({regionTitle, siteTitle, siteId, ...props}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const singles = useSingles()
    const refresh = () => window.location.reload(false)
    useCallback(() => {
        if (!singles) {
            dispatch(getSingles())
        }
    }, [dispatch, singles])
    const singlesFromSite = (siteId) =>
        singles?.filter((single) => single?.site?._id === siteId)
    if (!singlesFromSite(siteId)?.length) {
        return
    }
    return (
        <Menu.Item
            className={'list-menu-item'}
            key={uuidv4()}
            style={{
                textAlign: 'right',
                display: 'absolute',
                width: '200px',
            }}>
            <Site
                onClick={() => {
                    navigate(
                        '/site/' +
                            urlEncode(regionTitle) +
                            '/' +
                            urlEncode(siteTitle)
                    )
                    refresh()
                }}
                name={siteTitle}
                amount={singlesFromSite(siteId)?.length}></Site>
        </Menu.Item>
    )
}

export default MenuItemContainer
