import {React, styled} from 'common'
import {Colors, Fonts} from 'appearance'

const SubTitle = ({title, description}) => {
    if (!description || description === '') {
        return null
    }
    return (
        <Container>
            <Title>{title}:</Title>
            <Content>{description}</Content>
        </Container>
    )
}

export default SubTitle

const Container = styled.div``

const Title = styled.h4`
    font-family: ${Fonts.PRIMARY};
    color: ${Colors.PRIMARY};
`

const Content = styled.p`
    font-family: ${Fonts.PRIMARY};
    white-space: pre-line;
`
