const colors = {
    APP_WHITE: '#ffffff',
    APP_BLACK: '#000',
    APP_GRAY: '#ccc',
    APP_GRAY_DARK: '#aaa',
    APP_GREY_SECTION: '#f4f4f4',
    APP_GREEN_LIGHT: '#2b86864d',
    APP_BLOG_PAGE: '#1b3740',
    APP_DATE: '#777777',
    PRIMARY: '#f5761f',
    SECONDARY: '#08304a',
    PRIMARY_TEXT: '#45484b',

    BUTTON: {
        TEXT: '#333',
        HOVER: {
            BORDER: '#858585',
            BACKGROUND: '#ecdadaa1',
        },
    },

    SHARE: {
        BACKGROUND: '#5bc0de',
        BACKGROUND_CLICKED: '#31b0d5',
        BORDER: '#46b8da',
    },

    DRAWER: {
        BACKGROUND: '#f0ecdc',
        BUTTON_ACTIVE: '#bbbbbb',
    },

    DIFFICULTY: {
        Easy: {
            text: '#1c8718',
            background: '#1c8718',
        },
        Normal: {
            text: '#F1F11F',
            background: '#929200',
        },
        Hard: {
            text: '#FF0000',
            background: '#FF0000',
        },
    },
    SINGLE_HEIGHT_CHART: {
        BACKGROUND: 'rgb(229, 228, 226, 0.5)',
    },
}

export default colors
