import {useSelector} from 'react-redux'

export const useSites = () => useSelector((state) => state.hero.sites)

export const useSite = (siteName) =>
    useSelector((state) => state.hero.sites?.find((s) => s.title === siteName))

export const useRegions = () => useSelector((state) => state.hero.regions)

export const useRegionId = () => useSelector((state) => state.hero.regionId)

export const useSiteId = () => useSelector((state) => state.hero.siteId)

export const useDifficulty = () => useSelector((state) => state.hero.difficulty)

export const useFilteredSingles = () =>
    useSelector(
        (state) => {
            const singles = state.singles?.singles
            if (!singles) {
                return []
            }
            const search = state.hero.search
            const regionId = state.hero.regionId
            const siteId = state.hero.siteId
            const difficulty = state.hero.technicalDifficulty
            const filteredBySearch = !search
                ? singles
                : singles.filter((single) => single.title.includes(search, 0))
            const filteredSingles = filteredBySearch.filter(
                (single) =>
                    (!difficulty ||
                        single.technicalDifficulty === difficulty) &&
                    (!siteId || single.site._id === siteId) &&
                    (!regionId || single.site.region._id === regionId)
            )
            return filteredSingles
        },
        () => {}
    )
