import {React, styled} from 'common'
import {Colors, Icons} from 'appearance'

const CurrentLocation = ({onClick}) => {
    return <Btn onClick={onClick}>{Icons.location}</Btn>
}

export default CurrentLocation

const Btn = styled.button`
    width: 40px;
    height: 40px;
    background-color: ${Colors.APP_WHITE};
    border: none;
    font-size: 20px;
    &:hover {
        cursor: pointer;
    }
`
