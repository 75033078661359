import {React} from 'common'
import Page from 'components/Page/Page'
import {getSingles} from 'store/actions'
import {useDispatch, useSingles, useCallback} from 'services/hooks'
import NewSinglesList from 'components/NewSinglesList/NewSinglesList'

const NewSingles = () => {
    const dispatch = useDispatch()
    const singles = useSingles()

    const getScreenRequiredData = useCallback(() => {
        dispatch(getSingles())
    }, [dispatch])

    return (
        <Page
            title={'חדש במסנגלים'}
            screenRequiredData={[singles]}
            getScreenRequiredData={getScreenRequiredData}>
            <NewSinglesList />
        </Page>
    )
}

export default NewSingles
