const defaultState = {
    videos: [],
    auth: false,
    appId: null,
    articles: [],
    userName: null,
    initialized: false,
    cancelPadding: false,
    showFeaturedSingle: true,
}

const AppReducer = (state = defaultState, action) => {
    const actionObject = {
        SET_AUTH: () => {
            return {...state, auth: true}
        },
        SET_INITIALIZED: () => {
            return {...state, initialized: true}
        },
        SET_VIDEOS: () => {
            return {...state, videos: action.videos}
        },
        SET_USER_NAME: () => {
            return {...state, userName: action.userName}
        },
        SET_ARTICLES: () => {
            return {...state, articles: action.articles}
        },
        SET_CANCEL_PADDING: () => {
            return {...state, cancelPadding: action.hide}
        },
        SET_APP_ID: () => {
            return {...state, initialized: true, appId: action.appId}
        },
        SET_SHOW_FEATURED_SINGLE: () => {
            return {...state, showFeaturedSingle: action.showFeaturedSingle}
        },
    }
    const actionFunc = actionObject[action?.type]
    if (actionFunc) {
        return actionFunc(action)
    }

    return state
}

export default AppReducer
