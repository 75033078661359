import {React, styled} from 'common'
import ReactPlayer from 'react-player'
import {Colors, Fonts} from 'appearance'
import Date from 'components/Control/Display/Date'

const Video = ({src, title, date}) => {
    return (
        <Container>
            <Title>{title}</Title>
            <DateAndLine>
                <Date date={date} />
                <Line />
            </DateAndLine>
            <VideoFrame>
                <Player url={src} width="100%" height="100%" />
            </VideoFrame>
        </Container>
    )
}

export default Video

const VideoFrame = styled.div`
    width: 560px;
    position: relative;
    display: block;
    padding-bottom: 56.25%;
    @media (max-width: 670px) {
        width: auto;
    }
`

const Player = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
`

const Container = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-self: center;
    text-align: center;
    justify-content: center;
    @media (max-width: 670px) {
        padding: 15px 0 35px;
        width: 100%;
    }
    height: 485px;
    border-radius: 20px;
    background-color: ${Colors.APP_WHITE};
    margin: 50px 0px;
    padding: 15px 85px 35px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgb(1 1 1 / 10%);
    transition: box-shadow 0.3s ease-in-out;
    &:hover {
        box-shadow: 0 0px 20px rgb(1 1 1 / 20%);
    }
`
const Title = styled.h1`
    font-family: ${Fonts.PRIMARY};
    font-weight: 600;
    font-size: 24px;
    margin-top: 20px;
`

const DateAndLine = styled.div`
    margin: 0 0 5px 0;
`

const Line = styled.div`
    content: '';
    border-radius: 2px;
    width: 35px;
    height: 4px;
    margin: 10px auto;
    background: ${Colors.PRIMARY};
`
