import api from 'services/api'

export const setSearch = (search) => {
    return {
        type: 'SET_SEARCH',
        search,
    }
}

export const setMenuItems = (menuItems) => {
    return {
        type: 'SET_MENU_ITEMS',
        menuItems,
    }
}

export const getMenuItems = () => {
    return (dispatch, getState) => {
        api.getMenuItems().then(
            (res) => {
                dispatch(setMenuItems(res))
            },
            (err) => {
                console.warn('getMenuItems error', err)
            }
        )
    }
}

export const getSiteByTitle = async (title) => {
    try {
        return await api.getSiteByTitle(title)
    } catch (err) {
        console.warn('getSiteByTitle error', err)
    }
}
