import {Select} from 'antd'
import {v4 as uuidv4} from 'uuid'
import {React, styled} from 'common'
import {Colors, Images, Icons} from 'appearance'

const StyledSelect = ({
    title,
    items,
    onChangeHandler,
    value = items[0].text,
    ...props
}) => {
    const selectStyle = {
        background: Colors.PRIMARY,
        top: '500px !important',
        minWidth: '200px',
        width: '200px',
        left: '87px',
        direction: 'rtl',
        color: Colors.APP_WHITE,
    }

    return (
        <SelectOptions
            getPopupContainer={(trigger) => {
                return trigger
            }}
            dropdownAlign={{
                offset: [-10, 0],
            }}
            dropdownStyle={selectStyle}
            defaultValue={items[0].text}
            value={value}
            suffixIcon={Icons.menuExpand}
            onChange={onChangeHandler}
            dropdownRender={(menu, props) => <>{menu}</>}
            {...props}>
            {items?.map((item) => (
                <Select.Option
                    className={'select-item'}
                    value={item?.href}
                    key={uuidv4()}>
                    {item?.text}
                </Select.Option>
            ))}
        </SelectOptions>
    )
}

export default StyledSelect

const SelectOptions = styled(Select)`
    font-family: 'Varela Round', sans-serif;
    flex-grow: 3;
    font-weight: 500;
    text-align: center;
    padding: 12px 10px;
    min-width: 200px;
    border-radius: 34px;
    font-size: 18px;
    margin: 4px;
    color: ${Colors.PRIMARY};
    background: ${Colors.PRIMARY} url(${Images.glow}) no-repeat;
    background-position: 6.5px 5px;
    direction: rtl;

    .ant-select {
    }
    .ant-select-arrow {
        color: ${Colors.APP_WHITE} !important;
    }
    .ant-select-selector {
        background-color: ${Colors.PRIMARY} !important;
        border: none !important;
        border-radius: 34px !important;
    }
    .ant-select-selection-search {
    }
    .ant-select-selection-item {
        color: ${Colors.APP_WHITE} !important;
    }

    .ant-select-item {
        background-color: ${Colors.PRIMARY} !important;
        color: ${Colors.APP_WHITE};
    }
    .ant-select-item-option-selected {
        background-color: ${Colors.APP_GREEN_LIGHT} !important;
    }
    /*working :) --> :not(.ant-select-customize-input) .ant-select-selector {
        background-color: ${Colors.PRIMARY} !important;
    } */
    /* .ant-select-selection-search {
        background-color: ${Colors.PRIMARY};
    } */
`
