import {React, styled} from 'common'

const Title = ({title, children, ...props}) => {
    return (
        <Container>
            <SiteTitle>{title}</SiteTitle>
            {children}
        </Container>
    )
}

export default Title

const Container = styled.div`
    display: flex;
    align-items: center;
`

const SiteTitle = styled.span``
