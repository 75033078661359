import {Texts} from 'appearance'
import {React, styled} from 'common'

const Row = ({children}) => <Container>{children}</Container>

export default Row

const Container = styled(Texts.ContentDescription)`
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    text-align: justify;
`
