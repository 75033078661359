import {useState} from 'react'
import Section from './Section'
import {Colors} from 'appearance'
import {pageview} from 'react-ga'
import {React, styled} from 'common'
import {useEffect} from 'services/hooks'
import Loading from 'components/Page/Loading'
import Header from 'components/Page/Header/Header'
import {isNonEmptyArrays} from 'services/utils/utils'

const Page = ({
    pageTitle = 'מסנגלים',
    title,
    padding,
    children,
    screenRequiredData = [[true]],
    getScreenRequiredData,
}) => {
    const [isLoading, setIsLoading] = useState(true)
    const [showContent, setShowContent] = useState(false)
    const [drawerVisible, setDrawerVisible] = useState(false)

    useEffect(() => {
        pageview(window.location.pathname + window.location.search)
        if (!isNonEmptyArrays(screenRequiredData) && !isLoading) {
            setIsLoading(true)
        } else if (isNonEmptyArrays(screenRequiredData) && isLoading) {
            setIsLoading(false)
        }
    }, [isLoading, screenRequiredData])

    useEffect(() => {
        getScreenRequiredData && getScreenRequiredData()
        document.title = pageTitle
    }, [getScreenRequiredData, pageTitle])

    const toggleDrawer = () => {
        setDrawerVisible(!drawerVisible)
    }

    return showContent ? (
        <Container padding={padding}>
            <Section>
                <PageHeader
                    toggleDrawer={toggleDrawer}
                    drawerVisible={drawerVisible}
                />
            </Section>
            {title ? (
                <Section backgroundColor={Colors.APP_BLOG_PAGE}>
                    <Title>{title}</Title>
                </Section>
            ) : null}
            {children}
        </Container>
    ) : (
        <Loading isLoading={isLoading} setShowContent={setShowContent} />
    )
}

export default Page

const Container = styled.div`
    direction: rtl;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${({padding}) => padding || 0};
    box-sizing: border-box;
    margin-top: 14px;
    background-color: ${Colors.APP_WHITE};
    width: 100%;
`

const PageHeader = styled(Header)`
    background-color: ${Colors.APP_WHITE};
`

const Title = styled.div`
    /* background-color: ${Colors.APP_BLOG_PAGE}; */
    height: 243px;
    color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 31px;
    font-weight: 500;
    ::after {
        position: absolute;
        top: 272px;
        content: '';
        border-radius: 2px;
        width: 35px;
        height: 4px;
        background: ${Colors.PRIMARY};
    }
`
