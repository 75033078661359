const searchParams = new URLSearchParams(window.location.search)
const params = searchParams.get('single')
const singles = {
    'יער-שגב-פאמפ-טרק': 'פאמפ-טרק',
    'חוצה-רמון': 'חוצה-רמון',
    חלוקים: 'סינגל-חלוקים',
    'קומבינציה-4-גיני-בבקבוק': "קומבינציה-4---ג'יני-בבקבוק",
    'צופר-צוקים-פארן-2': 'סינגל-צופר--צוקים--פארן',
    'פארק-בריטניה-2017': 'פארק-בריטניה-2017',
    rachamim: 'סינגל-רחמים',
    'בארי-2': 'סינגל-בארי---דרומי--צפוני--יד-אנזק',
    'afternoon-ride': 'סינגלים-יער-ברזיל',
    'נחל-פרצים-מישור-עמיעז-נחל-סדום-5': 'נחל-פרצים--מישור-עמיעז--נחל-סדום',
    'חורשים-2017': 'חורשים-2017',
    'קומבינציית-גילגל': 'קומבינציה-5---גילגל',
    'סינגל-זכריה-2': 'סינגל-זכריה',
    'סינגל-בארי-יד-אנזק': 'סינגל-יד-אנזק',
    'סינגל-פלוגות': 'סינגל-פלוגות',
    'morning-ride': 'סינגל-אלון-ו--רימון',
    משואה: 'סינגל-משואה',
    'סינגל-ניר-משה': 'סינגל-ניר-משה',
    'עדולם-קנים-שמיניה': 'סינגל-עדולם---קנים-(השמיניה)',
    'אבטיח-והללויה': 'סינגל-אבטיח-והללויה',
    'דרך-הסוכר-1': 'סינגל-דרך-הסוכר',
    'קומבינציה-44': 'קומבינציה-3---מסע-אינטרוולים',
    'סינגל-רימון': 'סינגל-רימון',
    'סינגל-שוהם': 'סינגל-שוהם',
    'סינגל-גבעת-המורה': 'סינגל-גבעת-המורה',
    'סינגל-חרובית-סימון-ירוק-ושחור': 'סינגל-חרובית---ירוק-ושחור',
    'בן-שמן-ירוק-וכחול': 'סינגל-חדיד-והרצל---ירוק-וכחול',
    'סינגל-כברי-תוואי-סופי-שבוצע': 'סינגל-כברי',
    'סינגל-יחיעם': 'סינגל-יחיעם',
    'סינגל-הרי-נפתלי-רכס-רמים': 'סינגל-הרי-נפתלי---מצוק-רמים',
    'קומבינציה-2': 'קומבינציה-2',
    'סינגל-ירוק': 'סינגל-בארי-(מסלול-ירוק)',
    'סינגל-עדולם': 'סינגל-עדולם',
    'בן-שמן-קומבינציה-1': 'קומבינציה-1',
    'סינגל-ביריה': 'סינגל-ביריה',
    beitkeshet: 'סינגל-בית-קשת',
    'סינגל-גברעם-2': 'סובב-גברעם',
    'סינגל-כתום': 'סינגל-בארי-(מסלול-כתום)',
    'סינגל-כחול': 'סינגל-בארי-(מסלול-כחול)', //גם עין השופט מסלול כחול מוביל לאותו url
    'סינגל-כחול-וצהוב': 'סינגל-הזורע-+-עין-השופט',
    'סינגל-תומרת': 'סינגל-תומרת',
    'סינגל-אלון-הגליל': 'סינגל-אלון-הגליל',
    'סינגל-שימשית': 'סינגל-שימשית',
    'סינגל-ריש-לקיש-וסינגל-הושעיה': 'סינגל-ריש-לקיש-וסינגל-הושעיה',
    'סינגל-הושעיה': 'סינגל-הושעיה',
    'מסלול-צהוב': 'סינגל-הזורע',
    מרסבא: 'סינגל-מנזר-מרסבא',
    'קרן-אל-חאגר': 'סינגל-קרן-אל-חאגר',
    'סינגל-עינב': 'סינגל-עינב',
    'סינגל-האלה': 'סינגל-האלה',
    'חוצה-גולן-25-חלק-ב': 'בני-יהודה---חמת-גדר',
    'הר-חורשן': 'סינגלים-הר-חורשן',
    'סינגל-יער-להב': 'סינגל-אלון',
    'סינגל-בארי-סובב-צפוני': 'סינגל-בארי-(סימון-אדום)-+-סובב-צפוני',
    רומני: 'סינגל-הרומני',
    'גן-המשחקים': 'גן-המשחקים',
    'גינות-סלעים-3': 'גינות-סלעים',
    'untitled-layer_חורשים-חלק-1': 'סינגל-חורשים-1',
    'ריש-לקיש': 'סינגל-ריש-לקיש',
    'ריש-לקיש-שימשית': 'סינגל-ריש-לקיש-+-שימשית',
    'דרך-המעיינות': 'דרך-המעיינות',
    'פארק-קנדה-2-2': 'סינגל-שחור-+-סינגל-אדום',
    'פארק-בריטניה-מסלול-קצר': 'בריטניה---המסלול-הקצר',
    'פארק-קנדה-כל-הסינגלים': 'כל-הסינגלים',
    'פארק-קנדה-העליון-ו-לאורך-הגדר': 'עליון-ולאורך-הגדר',
    'פארק-קנדה-2': 'פארק-קנדה',
    סוללים: 'סינגל-הסוללים',
    עגור: 'סינגל-עגור',
    'עדולם-קנים': 'סינגל-עדולם---קנים',
    'פארק-בריטניה-מסלול-ארוך': 'בריטניה---המסלול-הארוך',
    'כרמי-יוסף-יער-המגינים': 'סינגלים-כרמי-יוסף',
    'מעורב-ירושלמי': 'מעורב-ירושלמי',
    מענית: 'מענית',
    'משמר-העמק': 'משמר-העמק',
    'יער-שגב-סינגל-1': 'סינגל-1',
    'יער-שגב-סינגל-2': 'סינגל-2',
    חרובית: 'סינגל-חרובית---ירוק',
    'יד-קנדי-עמינדב': 'יד-קנדי---עמינדב',
    בארי: 'סינגל-בארי-(סימון-אדום)',
    'גבעת-אלה': 'סינגל-גבעת-אלה',
    'דרך-הסוכר-2': 'סינגל-דרך-הסוכר',
    'זנוח-ודרך-הקיסר': 'סינגל-זנוח---הר-בהר---דרך-הקיסר',
    'שוקדה-מסלול-אדום-בארי': 'סינגל-שוקדה',
    'פארק-קנדה': 'פארק-קנדה',
    'עין-ראפה': 'עין-ראפה',
    sovev_arkov: 'סובב-ערקוב-(צפוני-ודרומי)',
    johnny: "סינגל-ג'וני-פרנקלין",
    anava: 'סינגל-ענבה---סימון-אדום',
    531: 'סינגל-חדיד---סימון-ירוק',
    'מ-מודיעין-הרצל': 'סינגל-הרצל---סימון-כחול',
}

const urlSingleTitle = () => singles[params]

export default urlSingleTitle
