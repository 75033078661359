import {Colors, Fonts} from 'appearance'
import {React, styled} from 'common'
import {urlEncode} from 'services/utils/utils'
import {useNavigate} from 'services/hooks'
import Date from 'components/Control/Display/Date'

const Article = ({content, title, date, preview}) => {
    const navigate = useNavigate()
    return (
        <Container
            onClick={() => {
                navigate('/article/' + urlEncode(title))
            }}
            preview={preview}>
            <Title>{title}</Title>
            <Time>
                <Date date={date} />
                <Line />
            </Time>
            <Content dangerouslySetInnerHTML={{__html: content}} />
        </Container>
    )
}

export default Article

const Container = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-self: center;
    text-align: center;
    justify-content: ${({preview}) => (preview ? 'start' : 'center')};
    width: auto;
    height: ${({preview}) => (preview ? '240px' : '100%')};
    border-radius: 20px;
    background-color: ${Colors.APP_WHITE};
    margin: 50px 15%;
    padding: 35px 62px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgb(1 1 1 / 10%);
    transition: box-shadow 0.3s ease-in-out;
    &:hover {
        box-shadow: 0 0px 20px rgb(1 1 1 / 20%);
        cursor: pointer;
    }
`

const Title = styled.h1`
    height: 50px;
    font-family: ${Fonts.PRIMARY};
    font-weight: 500;
    color: ${Colors.PRIMARY};
`

const Content = styled.p`
    height: 100%;
    font-family: ${Fonts.PRIMARY};
    font-size: 17px;
`

const Time = styled.p`
    height: 15px;
    margin: 0 0 50px 0;
`

const Line = styled.div`
    content: '';
    border-radius: 2px;
    width: 35px;
    height: 4px;
    margin: 10px auto;
    background: ${Colors.PRIMARY};
`
