export const difficulties = [
    {text: 'כל הרמות', href: null},
    {text: 'קל', href: 'Easy'},
    {text: 'בינוני', href: 'Normal'},
    {text: 'קשה', href: 'Hard'},
]

export const allSites = {text: 'כל האתרים', href: null}

export const allRegions = {text: 'כל האזורים', href: null}
