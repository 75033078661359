import {BASE_URL} from 'services/api'
import {useState} from 'services/hooks'
import {Icons, Colors} from 'appearance'
import {React, styled, i18n} from 'common'
import SubTitle from 'components/Single/SubTitle'
import Share from 'components/Single/Share/Share'
import {getHebrewHoursRange} from 'services/utils/utils'

const SingleDescription = ({
    single = {site: {title: '', region: {title: ''}}},
}) => {
    const {
        _id: id,
        type,
        title,
        shape,
        time: duration,
        totalLength: length,
        site: siteObj,
        directions,
        description,
        site: {region: regionObj},
        totalClimb: climbingHeight,
        physicalDifficulty,
        technicalDifficulty,
    } = single
    const site = siteObj.title
    const region = regionObj.title

    const singleType = () =>
        shape === 'Round' ? type + ' מעגלי' : type + ' לא מעגלי'

    const [showDownloadTracksBtn, setShowDoanloadTrackBtn] = useState(false)
    const copyURL = () => {
        navigator.clipboard.writeText(document.URL)
        alert('הקישור הועתק')
    }
    const showDownloadTrack = () =>
        setShowDoanloadTrackBtn(!showDownloadTracksBtn)
    return (
        <Container>
            <Title>{title}</Title>
            <SubTitle title="אזור" description={region} />
            <SubTitle title="אתר" description={site} />
            <SubTitle
                title="קושי טכני"
                description={
                    technicalDifficulty &&
                    i18n.t('difficulty.' + technicalDifficulty)
                }
            />
            <SubTitle
                title="קושי פיזי"
                description={
                    physicalDifficulty &&
                    i18n.t('difficulty.' + physicalDifficulty)
                }
            />
            <SubTitle
                title="גובה טיפוס מצטבר"
                description={climbingHeight + ` מ'`}
            />
            <SubTitle title="אורך מסלול" description={length + ` ק"מ`} />
            <SubTitle
                title="זמן"
                description={duration && getHebrewHoursRange(duration)}
            />
            <SubTitle
                title="סוג מסלול"
                description={shape ? singleType() : type}
            />
            <SubTitle title="תיאור מסלול" description={description} />
            <SubTitle title="הוראות הגעה" description={directions} />
            <TrackBtn onClick={showDownloadTrack}>
                הורד מסלול
                {Icons.menuExpand}
            </TrackBtn>
            {showDownloadTracksBtn && (
                <DispalyFlexRow>
                    <TrackBtnDownload
                        style={{marginLeft: 20}}
                        href={`${BASE_URL}/download/gpx/${id}`}
                        download={`${title}`}>
                        הורד gpx
                        {Icons.download}
                    </TrackBtnDownload>
                    <TrackBtnDownload
                        style={{marginRight: 20}}
                        href={`${BASE_URL}/download/kml/${id}`}
                        download={`${title}`}>
                        הורד kml
                        {Icons.download}
                    </TrackBtnDownload>
                </DispalyFlexRow>
            )}
            <DispalyFlexRow>
                <ShareHeader>שתף מסלול:</ShareHeader>
                <Share />
            </DispalyFlexRow>
            <DispalyFlexColumn>
                <ShareDirect>
                    להעתקת קישור ישיר לחץ
                    <CopyLink onClick={copyURL}> &nbsp;כאן</CopyLink>
                </ShareDirect>
            </DispalyFlexColumn>
        </Container>
    )
}

export default SingleDescription

const Container = styled.div`
    width: 360px;
    @media (max-width: 1200px) {
        width: 293px;
    }
    min-width: 250px;
    padding-right: 7px;
`

const downloadStyle = `
padding: 6px 12px;
border-radius: 4px;
border: solid 1px;
color: ${Colors.BUTTON.TEXT};
background-color: ${Colors.APP_WHITE};
border-color: ${Colors.APP_GRAY};
width: 100%;
&:hover {
    cursor: pointer;
    background-color: ${Colors.BUTTON.HOVER.BACKGROUND};
    border-color: ${Colors.BUTTON.HOVER.BORDER};
}
`

const Title = styled.h2`
    font-size: 30px;
`

const TrackBtn = styled.button`
    ${downloadStyle}
`

const TrackBtnDownload = styled.a`
    ${downloadStyle}
    padding: 6px 20px;
`

const ShareHeader = styled.h2``

const ShareDirect = styled.p``

const CopyLink = styled.span`
    color: blue;
    &:hover {
        cursor: pointer;
    }
`
const DispalyFlexRow = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const DispalyFlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 600px;
`
