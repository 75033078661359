import {React, styled} from 'common'
import {Page} from 'components/Page'
import Section from 'components/Page/Section'
import {urlDecode} from 'services/utils/utils'
import SlideShow from 'components/Single/SlideShow'
import {getSingleData} from 'services/utils/single'
import HeightChart from 'components/Single/HeightChart'
import Map from 'components/Control/Display/Map/SingleMap'
import SingleDescription from 'components/Single/SingleDescription'
import FacebookComments from 'components/Single/Single/FacebookComments'
import {useLocation, useState, useSingle, useMemo} from 'services/hooks'

const Single = () => {
    const location = useLocation()
    const singleName = urlDecode(location.pathname.split('/').pop())
    const single = useSingle(singleName)
    const {title, gallery} = single || {}

    const [cursorPosition, setCursorPosition] = useState(null)

    const {coordinates, waypoints, decimatedCoordinates, chartData} = useMemo(
        () => getSingleData(single),
        [single]
    )

    const url = window.location.href

    return (
        <Page pageTitle={title} screenRequiredData={[[single]]}>
            <Section>
                <SingleContainer>
                    <SingleDescription single={single} />
                    <Visuals>
                        <Map
                            coords={coordinates}
                            waypoints={waypoints}
                            cursorPosition={cursorPosition}
                            setCursorPosition={setCursorPosition}
                        />
                        <HeightChart
                            title={'גובה'}
                            chartData={chartData}
                            cursorPosition={cursorPosition}
                            setCursorPosition={setCursorPosition}
                            labelsCoords={decimatedCoordinates}
                        />
                        {gallery?.length > 0 && <SlideShow images={gallery} />}
                    </Visuals>
                </SingleContainer>
                <FacebookComments url={url} />
            </Section>
        </Page>
    )
}

export default Single

const SingleContainer = styled(Section)`
    @media (max-width: 800px) {
        padding: 0;
    }
    @media (max-width: 990px) {
        flex-flow: column-reverse nowrap;
    }
    display: flex;
    flex-flow: row nowrap;
`

const Visuals = styled.div`
    width: 100%;
    padding: 10px;
`
